<template>
	<div>

	</div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              tickerTimer: null,
              symbols:[],
              tickers:[],
              symbolPrice: {},
              api: config.baseUrl + '/exchange/okex/spot/public/ticker',
              environment: config.environment,
            }
        },
        created(){

        },
        mounted(){
          this.symbols = this.spotSymbolInfos.okex;
          this.$store.dispatch('marketPriceHandler', null);
          this.getTicker();
          this.tickerTimer = setInterval(() => {
            this.getTicker();
          }, 8000);
        },
        computed: {
            ...mapGetters(['lastMarketPrice', 'spotSymbolInfos', 'networkStatus'])
        },
        watch:{

        },
        methods: {
          // 获取OKEx Spot市场价
          getTicker(){
            // 未联网时不发送请求
            if (!this.networkStatus) {
              return;
            }
            if(this.$route.path.indexOf('/spot/okex') > -1 && this.api != null){
              this.$http.get(this.api).then(res => {
                  let data = res.data;
                  if (data == null) {
                    return;
                  }
                  for(let i = 0, iLen = this.symbols.length; i < iLen; i++){
                    for(let j = 0, jLen = data.length; j < jLen; j++){
                      if (this.symbols[i].exchangeSymbol == data[j].instrument_id) {
                        this.tickers[i] = data[j];
                        this.tickers[i].instrument_id = this.symbols[i].symbol;
                      }
                    }
                  }
                  for(let i = 0, len = this.tickers.length; i < len; i++){
                    let symbol = this.tickers[i].instrument_id;
                    let decimalPlace = this.getDecimalPlace(symbol);
                    let currentPrice = Number(this.tickers[i].last).toFixed(decimalPlace);
                    let bidPrice = Number(this.tickers[i].bid).toFixed(decimalPlace);
                    let askPrice = Number(this.tickers[i].ask).toFixed(decimalPlace);
                    let lastCurrentPrice = 0;
                    let lastBidPrice = 0;
                    let lastAskPrice = 0;
                    let currentColor = 'green--text';
                    let bidColor = 'green--text';
                    let askColor = 'green--text';
                    if (this.lastMarketPrice != null && this.lastMarketPrice[symbol]) {
                      lastCurrentPrice = Number(this.lastMarketPrice[symbol].current.price);
                      lastBidPrice = Number(this.lastMarketPrice[symbol].bid.price);
                      lastAskPrice = Number(this.lastMarketPrice[symbol].ask.price);
                      // current
                      if (Number(currentPrice) > Number(lastCurrentPrice)) {
                          currentColor = 'green--text';
                      } else if (Number(currentPrice) < Number(lastCurrentPrice)){
                          currentColor = 'red--text';
                      } else {
                          currentColor = this.lastMarketPrice[symbol].current.color;
                      }
                      // bid
                      if (Number(bidPrice) > Number(lastBidPrice)) {
                          bidColor = 'green--text';
                      } else if (Number(bidPrice) < Number(lastBidPrice)){
                          bidColor = 'red--text';
                      } else {
                          bidColor = this.lastMarketPrice[symbol].bid.color;
                      }
                      // ask
                      if (Number(askPrice) > Number(lastAskPrice)) {
                          askColor = 'green--text';
                      } else if (Number(askPrice) < Number(lastAskPrice)){
                          askColor = 'red--text';
                      } else {
                          askColor = this.lastMarketPrice[symbol].ask.color;
                      }
                    }
                    let current = {
                        'price': currentPrice,
                        'color': currentColor
                    };
                    let bid = {
                        'price': bidPrice,
                        'color': bidColor
                    };
                    let ask = {
                        'price': askPrice,
                        'color': askColor
                    };
                    this.symbolPrice[symbol] = {
                        'current': current,
                        'bid': bid,
                        'ask': ask
                    }
                  }
                  this.$store.dispatch('marketPriceHandler', this.symbolPrice);
                }, error => {

                })
              }
            },
            // 获取小数位
            getDecimalPlace(symbol){
              if (symbol == null) {
                return 8;
              }
              for(let i = 0, len = this.symbols.length; i < len; i++){
                if (this.symbols[i].symbol == symbol) {
                  return this.symbols[i].priceDecimalPlace;
                }
              }
              return 8;
            },
        },
        beforeDestroy() {
          if(this.tickerTimer) {
            clearInterval(this.tickerTimer);
          }
        },

    }
</script>
