<template>
  <v-layout row ml-4 mt-4>
    <v-flex xs12 sm12 md3>
      <div v-if="krakenPortfolioValue.length > 0 ">
        <div class="mb-3 fs-20">Kraken Portfolio Value :</div>
        <v-divider></v-divider>
        <div v-for="item in krakenPortfolioValue" :key="item.symbol">
          <div class="d-custom-flex">
            <v-flex xs2> {{ item.symbol}} :</v-flex>
            <v-flex xs5 class="text-xs-right">{{ formatNum(item.balance.toFixed(8)) + ' ' + item.symbol}}</v-flex>
            <v-flex xs5 class="text-xs-right">{{ formatNum(item.dollar.toFixed(2)) + ' ' + currency }}</v-flex>
          </div>
          <v-divider class="my-1"></v-divider>
        </div>
      </div>
    </v-flex>
    <v-flex xs12 sm12 md3 class="ml-5">
      <div v-if="okexPortfolioValue.length > 0 ">
        <div class="mb-3 fs-20">OKEx Portfolio Value :</div>
        <v-divider></v-divider>
        <div v-for="item in okexPortfolioValue" :key="item.symbol">
          <div class="d-custom-flex">
            <v-flex xs2> {{ item.symbol}} :</v-flex>
            <v-flex xs6 class="text-xs-right">{{ formatNum(item.balance.toFixed(8)) + ' ' + item.symbol}}</v-flex>
            <v-flex xs4 class="text-xs-right">{{ formatNum(item.dollar.toFixed(2)) + ' ' + currency }}</v-flex>
          </div>
          <v-divider class="my-1"></v-divider>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>
<style>

</style>
<script>
  import Vue from 'vue';
  import config from '@/config.js'
  import {
    mapGetters
  } from "vuex";
  export default {
    data() {
      return {
        krakenPortfolioValue: [],
        okexPortfolioValue: [],
        currency: "USD"
      }
    },
    props: {
      exchangeCode: String
    },
    computed: {
      ...mapGetters(['responsive'])
    },
    created() {

    },
    mounted() {
      this.getPV();
    },
    watch: {
      // exchangeCode(value){

      // }
    },
    methods: {
      // 获取PV
      getPV() {
        this.getKrakenPortfolioValue();
        this.getOkexPortfolioValue();
      },
      // 获得krakenPV
      getKrakenPortfolioValue() {
        this.krakenPortfolioValue = [];
        let api = config.baseUrl + "/exchange/kraken/futures/private/getPortfolioValue";
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
            this.krakenPortfolioValue = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 获得okexPV
      getOkexPortfolioValue() {
        this.okexPortfolioValue = [];
        let api = config.baseUrl + "/exchange/okex/futures/private/getPortfolioValue";
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200) {
            this.okexPortfolioValue = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 格式化数字
      formatNum(num) {
        return formatNumber(num);
      },

    },
  }
</script>
