<template>
  <div>
    <div class="d-none">
      <!-- 订阅价格数据 -->
      <div>
        <div v-if="exchangeCode == 'okex'">
          <okex-spot-market-price></okex-spot-market-price>
        </div>
        <div v-if="exchangeCode == 'ftx'">
          <ftx-spot-market-price></ftx-spot-market-price>
        </div>
      </div>
    </div>
    <v-container fluid class="grid-list-xl" :class="[!fullScreen?'mt-12':'pt-0 mt-12']" v-if="responsive == 'PC' && !fullScreen">
      <v-navigation-drawer fixed :width="370" class="z-index flex-m" :mini-variant.sync="mini" mini-variant-width="0">
          <template>
            <!-- <v-row class="mt-10 mr-0" align="center">
              <v-col cols="2">

              </v-col>
              <v-col cols="2">
                <v-icon color="grey" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
              </v-col>
              <v-col cols="4">
                <div class="fs-18 grey--text pointer" :class="[spotTradingQuoteCurrency == 'USDT' ? 'underline-common' : '']" @click="symbol='BTC-USDT', spotTradingQuoteCurrency = 'USDT'" href="#USDT">USDT</div>
              </v-col>
              <v-col cols="4">
                <div class="fs-18 grey--text pointer" :class="[spotTradingQuoteCurrency == 'BTC' ? 'underline-common' : '']" @click="symbol='ETH-BTC', spotTradingQuoteCurrency = 'BTC'" href="#BTC">CRYPTO</div>
              </v-col>
              </v-col>
            </v-row> -->
            <v-row class="mt-10 mr-0">
              <v-col cols="2" class="pt-0">
                <v-card tile flat class="pointer mt-10 symbol-overflow-spot firefox-scroll">
                  <div class="grey--text text--lighten-1 fs-8" v-if="currentExchangeSpotSymbolInfos != null">
                    <div v-for="(symbolObject, i) in currentExchangeSpotSymbolInfos" :key="i">
                      <!--  v-if="symbolObject.baseCurrency.toLowerCase().indexOf(searchCoinInput.toLowerCase()) > -1" -->
                      <v-tooltip right open-delay="100" close-delay="100">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            <router-link class="del-underline" :to="'/trading/spot/' + exchangeCode + '/' + symbolObject.symbol">
                              <v-card tile hover flat class="del-underline spot-symbol" :class="[symbolObject.symbol == symbol ? (darkMode ?'grey darken-4': 'white fs-10') : (darkMode?'darkbg':'greybg')]" @click="gotoSpotOrder(symbolObject.symbol)">
                                <span class="ml-1">{{ symbolObject.baseCurrency }}</span>
                              </v-card>
                            </router-link>
                          </span>
                        </template>
                        <span>{{ symbolObject.symbol }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="1" class="pt-0">
                    <v-icon color="grey" class="ml-2" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
                  </v-col>
                  <v-col cols="10" offset-md="0" class="pt-0 text-right">
                    <div class="fs-12">
                      <v-row :class="[orderForm.side == 'open_short' || orderForm.side == 'close_sell' ? 'red--text' : '']">
                        <v-col cols="4" class="pa-0">
                          {{$t("spotTrading.avaliable")}}:
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <span v-if="accounts != null && getBaseCurrency(exchangeCode, symbol) != ''">{{ formatNum(accounts[getBaseCurrency(exchangeCode, symbol)].avaliable.toFixed(8)) + ' ' + getBaseCurrency(exchangeCode, symbol) }}</span>
                          <span v-else>--</span>
                        </v-col>
                      </v-row>
                      <v-row :class="[orderForm.side == 'open_short' || orderForm.side == 'close_sell' ? 'red--text' : '']">
                        <v-col cols="4" class="pa-0">
                          {{$t("spotTrading.balance")}}:
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <span v-if="accounts != null && getBaseCurrency(exchangeCode, symbol) != ''">{{ formatNum(accounts[getBaseCurrency(exchangeCode, symbol)].balance.toFixed(8)) + ' ' + getBaseCurrency(exchangeCode, symbol) }}</span>
                          <span v-else>--</span>
                        </v-col>
                      </v-row>
                      <v-row :class="[orderForm.side == 'open_long' || orderForm.side == 'close_buy' ? 'green--text' : '']">
                        <v-col cols="4" class="pa-0">
                          {{$t("spotTrading.avaliable")}}:
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <span v-if="accounts != null && getQuoteCurrency(exchangeCode, symbol) != ''">{{ accounts[getQuoteCurrency(exchangeCode, symbol)].avaliable.toFixed(8) + ' ' + getQuoteCurrency(exchangeCode, symbol) }}</span>
                          <span v-else>--</span>
                        </v-col>
                      </v-row>
                      <v-row :class="[orderForm.side == 'open_long' || orderForm.side == 'close_buy' ? 'green--text' : '']">
                        <v-col cols="4" class="pa-0">
                          {{$t("spotTrading.balance")}}:
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <span v-if="accounts != null && getQuoteCurrency(exchangeCode, symbol) != ''">{{ accounts[getQuoteCurrency(exchangeCode, symbol)].balance.toFixed(8) + ' ' + getQuoteCurrency(exchangeCode, symbol) }}</span>
                          <span v-else>--</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="lightred--text mt-7 text-right">
                      <span class="fs-14">
                        <span class="pr-1">{{ symbol }}:</span>
                        <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].current != null" :class="marketPrice[symbol].current.color" class="pointer fs-18" @click="enterOrderPrice(marketPrice[symbol].current.price)">{{ formatNum(marketPrice[symbol].current.price)}}</span>
                      </span>
                    </div>
                    <div class="mt-7 mr-3">
                      <change-percentage :currency="getBaseCurrency(exchangeCode, symbol)"></change-percentage>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-5 ml-2">
                  <!-- *******************发送订单的表单******************* -->
                  <v-form ref="orderForm">
                    <!-- open/close -->
                    <v-col cols="12">
                      <div class="text-center grey--text text--darken-1 pointer mr-5">
                        <span class="pointer fs-20 mr-8" :class="[side == 'open' ? 'fs-28 grey--text darken-3' : '']"
                          @click="changeSide('open')">{{$t("spotTrading.open")}} </span>
                        <span class="pointer fs-20" :class="[side == 'close' ? 'fs-28  grey--text darken-3' : '']"
                          @click="changeSide('close')">{{$t("spotTrading.close")}}</span>
                      </div>
                    </v-col>
                    <!-- buy/sell -->
                    <v-col cols="12" class="mb-3">
                      <div class="text-lg-center grey--text text--darken-1 pointer mr-5">
                        <span class="pointer fs-20 mr-8" :class="[orderSide == 'buy' ? 'fs-36 teal--text darken-4' : '']"
                          @click="changeOrderSide('buy')">{{$t("spotTrading.buy")}}</span>
                        <span class="pointer fs-20" :class="[orderSide == 'sell' ? 'fs-36 red--text' : '']" @click="changeOrderSide('sell')">{{$t("spotTrading.sell")}}</span>
                      </div>
                    </v-col>
                    <!-- exchange -->
                    <v-col cols="12" class="py-0 px-6">
                      <v-select v-model="orderForm.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                        item-text="name" item-value="code" :rules="[v => !!v || $t('spotTrading.requiredMessage.exchangeMsg')]"
                        required @change="changeExchange"></v-select>
                    </v-col>
                    <!-- strategy -->
                    <v-col cols="12" class="py-0 px-6">
                      <v-select v-model="orderForm.strategy" :items="strategies" :label="$t('common.strategy')" required>
                        <template v-slot:selection>
                          <span :style="{color: getColorByStrategy(orderForm.strategy)}">{{ orderForm.strategy }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- category -->
                    <v-col cols="12" class="py-0 px-6">
                      <v-select v-model="orderForm.category" :items="getCategoriesByStrategy(orderForm.strategy)"
                        :label="$t('common.category')" required></v-select>
                    </v-col>
                    <!-- size -->
                    <v-col cols="12" class="py-0 px-6">
                      <v-text-field v-model="orderForm.size" type="number" :min="getMinSize(orderForm.exchange, orderForm.symbol)"
                        :label="$t('spotTrading.size')" :suffix="getBaseCurrency(orderForm.exchange, orderForm.symbol) + sizeDollarValue"
                        required :step="getSizeIncrement(orderForm.exchange, orderForm.symbol)"></v-text-field>
                    </v-col>
                    <!-- price -->
                    <v-col cols="12" class="py-0 px-6">
                      <v-text-field v-model="orderForm.price" type="number" min="0" :label="$t('spotTrading.price')"
                        :suffix="getQuoteCurrency(exchangeCode, symbol)" :step="getTickSize(orderForm.exchange, orderForm.symbol)" required></v-text-field>
                      <div class="fs-12">{{ $t('spotTrading.requiredMessage.minimumSizeMsg') + getMinSize(orderForm.exchange, orderForm.symbol) + ' ' + getBaseCurrency(orderForm.exchange, orderForm.symbol) }}</div>
                      <div class="fs-12 mt-1">{{ $t('spotTrading.requiredMessage.sizeIncrementMsg') + getSizeIncrement(orderForm.exchange, orderForm.symbol) + ' ' + getBaseCurrency(orderForm.exchange, orderForm.symbol) }}</div>
                    </v-col>
                    <!-- take profit -->
                    <v-col cols="12" v-if="'open' == side" class="px-6 mt-5">
                      <v-row>
                        <v-col cols="6" class="pa-0">
                          <v-checkbox color="lightred" v-model="takeProfitEnabled" :label="$t('spotTrading.takeProfit')"></v-checkbox>
                        </v-col>
                        <v-col cols="6" class="pa-0">
                          <v-text-field dense v-if="takeProfitEnabled" v-model="orderForm.takeProfitPrice" type="number"
                            min="0" :step="getTickSize(orderForm.exchange, orderForm.symbol)"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col col="4" class="mx-5 pa-0 ma-0 py-0" v-if="'open' == side">
                    </v-col>
                    <!-- </v-layout>
                     </v-flex> -->
                    <v-col cols="12" class="py-1 px-6 mt-3">
                      <v-btn tile block :disabled="isDisabledOrderButton" :loading="submitBtnLoading" color="white--text"
                        :class="['long' == buttonSide || 'buy' ==  buttonSide? 'green' : 'red']" @click="judgeParameters(true)">
                        {{ getOrderButtonSide() }}
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-row>
              </v-col>
            </v-row>
          </template>
      </v-navigation-drawer>
    </v-container>
    <!-- 中间显示 -->
    <v-container fluid class="flex-m grid-list-xl px-0 pb-0 pt-0 pa-0" v-if="responsive == 'PC' && !fullScreen">
      <v-app-bar outlined class="Vue-toolbar mt-9 pt-3 z-index-1" fixed :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
        <div class="fs-28" :class="[fullScreen ? '' : ( mini ? 'pl-5' : 'ml-420')]" style="display: flex; flex-wrap: wrap;">
          <span class="pl-0 pr-3 align-self-sm-center">
            <v-icon @click.stop="mini = !mini" v-if="!mini">mdi-chevron-left</v-icon>
            <v-icon @click.stop="mini = !mini" v-if="mini">mdi-chevron-right</v-icon>
          </span>
          <span class="pointer exchange-spot" @click="openExchangePage">{{ getExchangeNameByCode(exchangeCode)+" "+$t('common.spot') }}</span>
          <span> :</span>
          <span class="ml-2">{{ symbol }}</span>
          <span class="ml-2">
            <search-coin></search-coin>
          </span>
          <span class="ml-2">
            <v-btn x-small tile outlined class="mr-2 mb-2 pointer text-none" :class="childComponent == 'candle' ? 'lineblue--text' : ''" @click="childComponent = 'candle'">{{ $t('common.candle') }}</v-btn>
            <v-btn x-small tile outlined class="pointer mr-2 mb-2 text-none" :class="childComponent == 'heikinAshi' ? 'lineblue--text' : ''" @click="childComponent = 'heikinAshi'">{{ $t('common.heikinAshi') }}</v-btn>
            <v-btn x-small tile outlined class="mr-2 mb-2 pointer text-none" :class="childComponent == 'renko' ? 'lineblue--text' : ''" @click="childComponent = 'renko'">{{ $t('common.renko') }}</v-btn>
            <v-btn x-small tile outlined class="mr-2 mb-2 pointer text-none" :class="childComponent == 'summary'? 'lineblue--text' : ''" @click="childComponent = 'summary'">{{ $t('common.summary') }}</v-btn>
          </span>
        </div>
        <div class="navbar-right fs-36 mr-3 mb-2">
          <sub class="fs-14">{{$t("spotTrading.bid")}} </sub>
          <span class="green--text fw-normal">
            <span v-if="marketPrice != null  && marketPrice[symbol] != null && marketPrice[symbol].bid != null" :class="marketPrice[symbol].bid.color" class="pointer" @click="enterOrderPrice(marketPrice[symbol].bid.price)">{{ formatNum(marketPrice[symbol].bid.price)}}</span>
            <span v-else>--</span>
          </span>
          <span class="fw-normal mx-2"> |</span>
          <sub class="fs-14">{{$t("spotTrading.ask")}} </sub>
          <span class="red--text fw-normal">
            <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].ask != null" :class="marketPrice[symbol].ask.color" class="pointer" @click="enterOrderPrice(marketPrice[symbol].ask.price)">{{ formatNum(marketPrice[symbol].ask.price)}}</span>
            <span v-else>--</span>
          </span>
        </div>
      </v-app-bar>
      <v-row class="px-2 mt-3">
        <v-col cols="12" class="mr-5" :class="[fullScreen ? '' : ( mini ? 'pl-5' : 'ml-420')]">
          <trading-candle-chart v-if="childComponent == 'candle'" :exchange="exchangeCode" :tradingCategory="'spot'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.exchange, orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-candle-chart>
          <trading-heikin-ashi-chart v-if="childComponent == 'heikinAshi'" :exchange="exchangeCode" :tradingCategory="'spot'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.exchange, orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-heikin-ashi-chart>
          <trading-renko-chart v-show="childComponent == 'renko'" ref='tradingRenkoChart' :strategy="orderForm.strategy" :symbol="orderForm.symbol" :tradingCategory="'spot'" :childComponent="childComponent" :takeProfitPrice="renkoChartTakeProfitPrice" :stopLossPrice="renkoChartStopLossPrice" @changeRenkoStrategy="receiveChangeRenkoStrategy" @transferPrice="receiveRenkoPrice" @resetTakeAndStopPrice="receiveResetTakeAndStopPrice" ></trading-renko-chart>
          <asset-summary v-show="childComponent == 'summary'" :mini="mini" ref='summary' :hideNonePositions="hideNonePositions" ></asset-summary>
        </v-col>
      </v-row>
    </v-container>
    <!-- 订单表格 -->
    <v-container fluid class="flex-m grid-list-xl px-0 pb-0 pt-0 pa-0" v-if="responsive == 'PC'" :class="[!fullScreen?'pt-0':'mt-0 pt-10']">
      <v-row :class="[fullScreen ? 'ml-0' : ( mini ? 'pl-5' : 'ml-420')]">
        <v-col cols="12" class="pl-0 pt-0">
          <!-- tab的标签 -->
          <v-tabs slider-color="lightred" :slider-size="5" color="grey">
            <v-tab @click="tab='pendingEntryOrders'" class="px-3">
              <v-badge color="vividyellow" light :value="pendingEntryOrdersTotalElements">
                <template v-slot:badge>
                  <span v-if=" pendingEntryOrdersTotalElements > 0" class="black--text">{{pendingEntryOrdersTotalElements}}</span>
                </template>
                <span>{{$t("spotTrading.pendingEntryOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第二页 -->
            <v-tab @click="tab='pendingExitOrders'" class="px-3">
              <v-badge color="vividyellow" :value="pendingExitOrdersTotalElements">
                <template v-slot:badge v-if=" pendingExitOrdersTotalElements > 0">
                  <span class="black--text">{{pendingExitOrdersTotalElements}}</span>
                </template>
                <span>{{$t("spotTrading.pendingExitOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第三页 -->
            <v-tab @click="tab='submittedExchangeOrders'" class="px-3">
              <span>{{$t("spotTrading.submittedExchangeOrders")}}</span>
            </v-tab>
            <!-- 第四页 -->
            <v-tab @click="tab='unfilledExchangeOrders'" class="px-3">
              <v-badge color="vividyellow" :value="unfilledExchangeOrdersTotalElements">
                <template v-slot:badge v-if=" unfilledExchangeOrdersTotalElements > 0">
                  <span class="black--text">{{unfilledExchangeOrdersTotalElements}}</span>
                </template>
                <span>{{$t("spotTrading.unfilledExchangeOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第五页 -->
            <v-tab @click="tab='filledExchangeOrders'" class="px-3">
              <span>{{$t("spotTrading.filledExchangeOrders")}}</span>
            </v-tab>
            <!-- 第六页 -->
            <v-tab @click="tab='openPositions'" class="px-3">
              <v-badge color="vividyellow" :value="openPositionsOrdersTotalElements">
                <template v-slot:badge v-if=" openPositionsOrdersTotalElements > 0">
                  <span class="black--text">{{openPositionsOrdersTotalElements}}</span>
                </template>
                <span>{{$t("spotTrading.openPositions")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第七页 -->
            <v-tab @click="tab='openPositionsAtExchanges'" class="px-3">
              <v-badge color="vividyellow" :value="openPositionsAtExchanges.length">
                <template v-slot:badge v-if="openPositionsAtExchanges.length > 0">
                  <span class="black--text">{{openPositionsAtExchanges.length}}</span>
                </template>
                <span>{{$t("spotTrading.openPositionsAtExchanges")}}</span>
              </v-badge>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="mt-2">
              <!-- 刷新按钮 -->
              <v-btn :title="$t('spotTrading.refresh')" icon @click="refreshAllSpotOrders">
                <v-icon medium color="lightred">mdi-refresh</v-icon>
              </v-btn>
              <!-- 批量编辑 -->
              <!-- <v-btn :title="$t('spotTrading.batchEdit')" icon @click="gotoBatchOrder">
                <v-icon medium color="lightred">mdi-pencil</v-icon>
              </v-btn> -->
              <!-- 隐藏未持仓货币 -->
              <v-btn :title="$t('common.hideNonePositions')" icon class="mx-0" @click="hideNonePositions = !hideNonePositions">
                <v-icon medium color="lightred" v-if="!hideNonePositions">mdi-checkbox-blank-outline</v-icon>
                <v-icon medium color="lightred" v-if="hideNonePositions">mdi-checkbox-marked</v-icon>
              </v-btn>
              <!-- 交易记录 -->
              <router-link class="del-underline" to="/user/logs#spot">
                <v-btn :title="$t('log.orderHistory')" icon class="mx-0">
                  <v-icon medium color="lightred">mdi mdi-file-document-box</v-icon>
                </v-btn>
              </router-link>
              <!-- 全屏按钮 -->
              <v-btn :title="$t('spotTrading.fullScreen')" icon class="mx-0" @click="fullScreen = !fullScreen">
                <v-icon medium color="lightred" v-if="!fullScreen">mdi-fullscreen</v-icon>
                <v-icon medium color="lightred" v-if="fullScreen">mdi-fullscreen-exit</v-icon>
              </v-btn>
            </div>
          </v-tabs>
          <!-- 内容 -->
          <v-tabs-items v-model="tab">
            <!-- 第一页未提交至交易所的订单 PENDING ENTRY ORDERS -->
            <v-tab-item value="pendingEntryOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <!-- <v-card :class="[fullscreen?'h-vh':'']" flat> -->
                  <v-data-table dense :headers="pendingEntryOrdersHeader" :items="pendingEntryOrders" :no-data-text="pendingEntryLoading ? '':$t('spotTrading.noDataAvailable')" :items-per-page="pageSize" hide-default-footer>
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.id }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class="props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td class="text-right">
                          <v-tooltip open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span v-if="props.item.takeProfitPrice == 0">--</span>
                                <span v-else-if="props.item.side == 'open_long'">{{ ((props.item.takeProfitPrice / props.item.price - 1) * 100).toFixed(2) + '%' }}</span>
                                <span v-else-if="props.item.side == 'open_short'">{{ ((props.item.price / props.item.takeProfitPrice - 1) * 100).toFixed(2) + '%' }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.takeProfitPrice }}</span>
                          </v-tooltip>
                        </td>
                        <td class="text-right"><v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ (props.item.trailingStopLevel1 * 100).toFixed(2) + '%'  }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.stopLossPrice1  }}</span>
                          </v-tooltip>
                        </td>
                        <td class="text-right"><v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ (props.item.trailingStopLevel1 * 100).toFixed(2) + '%'  }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.stopLossPrice  }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.sendOrderStartTimestamp ? 'green--text' : 'grey--text'">
                                {{formatDate(props.item.sendOrderStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.sendOrderStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.sendOrderEndTimestamp ? $t('spotTrading.infinity') : formatDate(props.item.sendOrderEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <span>
                            <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.id)"
                              :title="props.item.id">mdi-content-copy</v-icon>
                            <v-icon color="orange" size="20" class="pointer ml-1" @click="showChangeOrderDialog(props.item)"
                              :title="$t('common.edit')">mdi-pencil</v-icon>
                            <v-icon color="blue" size="20" class="pointer ml-1" @click="showChangeTimeDialog(props.item)"
                              :title="$t('common.time')">mdi-clock-outline</v-icon>
                            <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-1" @click="showChangeNoteDialog('pendingEntry', props.item)"
                              :title="$t('common.strategy')">icon icon-s1</v-icon>
                            <v-icon color="red" size="20" class="pointer ml-1" @click="deleteUnsubmittedOrderById(props.item.id)"
                              :title="$t('common.cancel')">mdi-close</v-icon>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="pendingEntryLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </v-col>
              <!-- 底部分页 -->
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="pendingEntryOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="pendingEntryOrdersPageNo" :length="pendingEntryOrdersTotalPage" :total-visible="6"
                        @input="queryPendingEntryOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                  </v-row> 
              </v-row>
            </v-tab-item>
            <!-- 第二页 PENDING Exit ORDERS -->
            <v-tab-item value="pendingExitOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <!-- <v-card flat :class="[fullscreen?'h-vh':'']"> -->
                  <v-data-table dense :headers="pendingExitOrdersHeader" :items="pendingExitOrders" hide-default-footer
                    :no-data-text="pendingExitLoading ? '':$t('spotTrading.noDataAvailable')" :items-per-page="pageSize">
                    <template v-slot:items="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.id }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.sendOrderStartTimestamp ? 'green--text' : 'grey--text'">
                                {{formatDate(props.item.sendOrderStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.sendOrderStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.sendOrderEndTimestamp ? $t('spotTrading.infinity') : formatDate(props.item.sendOrderEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <span>
                            <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.id)"
                              :title="props.item.id">mdi-content-copy</v-icon>
                            <v-icon color="orange" size="20" class="pointer ml-1" @click="showChangeOrderDialog(props.item)"
                              :title="$t('common.edit')">mdi-pencil</v-icon>
                            <v-icon color="blue" size="20" class="pointer ml-1" @click="showChangeTimeDialog(props.item)"
                              :title="$t('common.time')">mdi-clock-outline</v-icon>
                            <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-1" @click="showChangeNoteDialog('pendingExit', props.item)"
                              :title="$t('common.strategy')">icon icon-s1</v-icon>
                            <v-icon color="red" size="20" class="pointer ml-1" @click="deleteUnsubmittedOrderById(props.item.id)"
                              :title="$t('common.cancel')">mdi-close</v-icon>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="pendingExitLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="pendingExitOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="pendingExitOrdersPageNo" :length="pendingExitOrdersTotalPage" :total-visible="6"
                        @input="queryPendingExitOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                  </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第三页提交至交易所的订单 -->
            <v-tab-item value="submittedExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="submittedOrderHeader" :items="submittedOrders" :no-data-text="submittedExchangeOrdersLoading ? '':$t('spotTrading.noDataAvailable')" :items-per-page="pageSize"
                    hide-default-footer>
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.spotOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td><span v-status="props.item.status"></span></td>
                        <td>{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.spotOrderId)"
                            :title="props.item.spotOrderId">mdi-content-copy</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="submittedExchangeOrdersLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="submittedOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="submittedOrdersPageNo" :length="submittedOrdersTotalPage" :total-visible="6" @input="querySubmittedOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                  </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第四页 UNFILLED AT EXCHANGES -->
            <v-tab-item value="unfilledExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="unfilledExchangeOrdersHeader" :items="unfilledExchangeOrders" :items-per-page="pageSize"
                    hide-default-footer :no-data-text="unfilledAtExchangesLoading ? '':$t('spotTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.spotOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.takeProfitPrice > 0 ? formatNum(props.item.takeProfitPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.takeProfitPrice > 0 && props.item.takeProfitPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.takeProfitPrice > props.item.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.stopLossPrice1 > 0 ? formatNum(props.item.stopLossPrice1.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice1 < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice1 > props.item.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" v-if="!props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.stopLossPrice > 0 ? formatNum(props.item.stopLossPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                              <span v-on="on" v-if="props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.fixedStopLossPrice > 0 ? formatNum(props.item.fixedStopLossPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.fixedStopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.fixedStopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                            <span v-if="!props.item.beFixedStopLossPrice">{{$t('common.trailing')}}</span>
                            <span v-if="props.item.beFixedStopLossPrice">{{$t('common.fixed')}}</span>
                          </v-tooltip>
                        </td>
                        <td :class="currentTimestamp >= props.item.timestamp ? 'green--text' : 'grey--text'">{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.spotOrderId)"
                            :title="props.item.spotOrderId">mdi-content-copy</v-icon>
                          <v-icon color="orange" size="20" class="pointer ml-1" @click="showChangeOrderDialog(props.item)"
                            :title="$t('common.edit')">mdi-pencil</v-icon>
                          <v-icon color="red" size="20" class="pointer ml-1" @click="deleteSubmittedOrderById(props.item.id)"
                            :title="$t('common.cancel')">mdi-close</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="unfilledAtExchangesLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->      
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="unfilledExchangeOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="unfilledExchangeOrdersPageNo" :length="unfilledExchangeOrdersTotalPage" :total-visible="6"
                        @input="queryUnfilledExchangeOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                  </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第五页 Filled ORDERS -->
            <v-tab-item value="filledExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="filledExchangeOrdersHeader" :items="filledExchangeOrders" :items-per-page="pageSize"
                    hide-default-footer :no-data-text="filledExchangeOrdersLoading ? '':$t('spotTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.spotOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td><span v-status="props.item.status"></span></td>
                        <td>{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.spotOrderId)"
                            :title="props.item.spotOrderId">mdi-content-copy</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="filledExchangeOrdersLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="filledExchangeOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="filledExchangeOrdersPageNo" :length="filledExchangeOrdersTotalPage" :total-visible="6"
                        @input="queryFilledExchangeOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                      <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                  </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第六页持仓订单 -->
            <v-tab-item value="openPositions">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="openPositionsOrdersHeader" hide-default-footer :items="openPositionsOrders" :items-per-page="pageSize"
                    :no-data-text="openPositionsLoading ? '':$t('spotTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.spotOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol.toUpperCase() }}</td>
                        <td :class="props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text'">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) }}</td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.takeProfitPrice > 0 ? formatNum(props.item.takeProfitPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.takeProfitPrice > 0 && props.item.takeProfitPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.takeProfitPrice > props.item.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.stopLossPrice1 > 0 ? formatNum(props.item.stopLossPrice1.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice1 < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice1 > props.item.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <v-tooltip bottom open-delay="100" close-delay="100" v-if="!props.item.beFixedStopLossPrice">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ formatNum(props.item.stopLossPrice > 0 ? formatNum(props.item.stopLossPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                            <span>{{$t('common.trailing')}}</span>
                          </v-tooltip>
                          <v-tooltip bottom open-delay="100" close-delay="100" v-if="props.item.beFixedStopLossPrice">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ formatNum(props.item.fixedStopLossPrice > 0 ? formatNum(props.item.fixedStopLossPrice.toFixed(getDecimalPlace(props.item.exchange, props.item.symbol))) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.fixedStopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.fixedStopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                              <span>{{$t('common.fixed')}}</span>
                          </v-tooltip>
                        </td>            
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.stopLoss1StartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.stopLoss1StartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.stopLoss1StartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.stopLoss1EndTimestamp ? $t('spotTrading.infinity') : formatDate(props.item.stopLoss1EndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td v-show="!props.item.beFixedStopLossPrice">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.stopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.stopLossStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.stopLossStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.stopLossEndTimestamp ? $t('spotTrading.infinity') : formatDate(props.item.stopLossEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td v-show="props.item.beFixedStopLossPrice">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.fixedStopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.fixedStopLossStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.fixedStopLossStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.fixedStopLossEndTimestamp ? $t('spotTrading.infinity') : formatDate(props.item.fixedStopLossEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(props.item.spotOrderId)"
                            :title="props.item.spotOrderId">mdi-content-copy</v-icon>
                          <v-icon color="orange" size="20" class="pointer ml-1" :title="$t('common.edit')" @click="showChangeOrderDialog(props.item)">mdi-pencil</v-icon>
                          <v-icon color="green" size="20" class="pointer ml-1" :title="$t('common.split')" @click="showSplitOrderDialog(props.item)">mdi-content-cut</v-icon>
                          <v-icon color="blue" size="20" class="pointer ml-1" :title="$t('common.time')" @click="showChangeTimeDialog(props.item)">mdi-clock-outline</v-icon>
                          <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-1" @click="showChangeNoteDialog('openPositions', props.item)"
                            :title="$t('common.strategy')">icon icon-s1</v-icon>
                          <v-icon color="red" size="20" class="pointer ml-1" :title="$t('common.close')" @click="showExitPositionOrderDialog(props.item)">mdi-close</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-if="openPositionsLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                    <v-col cols="auto" class="ma-4" v-show="openPositionsOrders.length > 0">
                      <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right"
                        v-model="openPositionsOrdersPageNo" :length="openPositionsOrdersTotalPage" :total-visible="6"
                        @input="queryOpenPositionsOrdersByPages"></v-pagination>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="1">
                        <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')" item-text="name" item-value="code" clearable></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-select class="fs-14 mr-4" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
                    </v-col>
                    
                </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第七页交易所持仓订单 -->
            <v-tab-item value="openPositionsAtExchanges">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense class="pr-3" :headers="openPositionsAtExchangesHeader" :items="openPositionsAtExchanges" :items-per-page="openPositionsAtExchangesPagination.rowsPerPage"
                    hide-default-footer :options.sync="openPositionsAtExchangesPagination" :no-data-text="openPositionsAtExchangesLoading ? '':$t('spotTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td><span :class="CommonJS.formatExchangeColor(props.item.exchange)">{{ props.item.exchange }}</span></td>
                        <td><span>{{ props.item.currency }}</span></td>
                        <td :class="props.item.side == 'Long'|| props.item.side == 'buy' ? 'green--text' : 'red--text' ">{{ props.item.side }}</td>
                        <td class="text-right">{{ formatNum(props.item.size) }}</td>
                        <td class="text-right">{{ formatNum(props.item.price) }}</td>
                        <td class="text-right" :title="props.item.value" :class="props.item.value == 0 ? '' : props.item.value > 0 ? 'green--text' : 'red--text' ">
                          {{ props.item.value < 0 ? '(' : '' }}
                          {{ props.item.value < 0 ? ('$' + formatNum(-props.item.value.toFixed(4))) : ('$' + formatNum(props.item.value.toFixed(4))) }}
                          {{ props.item.value < 0 ? ')' : '' }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-if="openPositionsAtExchangesLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </v-col>
                <!-- 底部分页 -->
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="openPositionsAtExchanges.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="openPositionsAtExchangesPagination.page"
                      :length="openPositionsAtExchangesTotalPage" :total-visible="10"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="openPositionsExchangeSearch" :items="exchanges" :label="$t('spotTrading.exchange')"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="openPositionsCurrencySearch" :label="$t('assets.currency')" :items="currencies" clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <!-- *******************提示价格差距过大的模态框******************* -->
    <v-row>
      <v-dialog v-model="largePriceGapDialog" persistent width="490">
        <v-card>
          <v-card-title>
            <span class="black--text">{{$t("spotTrading.waring")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="largePriceGapDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="fs-16">{{$t("spotTrading.largePriceWaring")}}</div>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small class="yellow darken-2 black--text" @click="largePriceGapDialog = false">
              {{$t("spotTrading.continueEditing")}}
            </v-btn>
            <v-btn small v-if="responsive == 'PC'" class="flex-m blue darken-2 white--text" @click="judgeParameters(false)">
              {{$t("spotTrading.confirm")}}
            </v-btn>
            <v-btn small v-if="responsive == 'mobile'" class="flex-b blue darken-2 white--text" @click="mobileJudgeParameters(false)">
              {{$t("spotTrading.confirm")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************设置止损价的模态框******************* -->
    <v-row>
      <v-dialog v-model="trailingStopDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.stopLoss")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="trailingStopDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row no-gutters v-if="orderForm.category != 'MR'" class="mb-9">
              <v-col cols="12" class="text-right">
                <v-btn-toggle v-model="planVal" dense tile>
                  <v-btn text value="0" small>{{$t("spotTrading.reset")}}</v-btn>
                  <v-btn text value="1" small>{{$t("spotTrading.planA")}}</v-btn>
                  <v-btn text value="2" small>{{$t("spotTrading.planB")}}</v-btn>
                  <v-btn text value="3" small>{{$t("spotTrading.planC")}}</v-btn>
                  <v-btn text value="4" small>{{$t("spotTrading.planD")}}</v-btn>
                  <v-btn text value="5" small>{{$t("spotTrading.planE")}}</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <!-- 止损价百分比计划设置 -->
            <div v-if="'close' == side" class="px-3">
              <span class="fs-22">{{$t("spotTrading.cannotSetStopPrice")}}</span>
            </div>
            <div v-if="'close' != side" class="px-3">
              <!-- Trailing Stop1 -->
              <v-row no-gutters class="red--text">
                <v-col cols="12" sm="6" md="6">{{$t("spotTrading.safetyNet")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field v-model="trailingStopLevel1" type="number" min="0" max="100" suffix="%" dense></v-text-field>
                </v-col>
              </v-row>
              <!-- Price2 -->
              <v-row no-gutters class="green--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6">{{$t("spotTrading.priceLevel2")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field v-model="priceLevel2" type="number" min="0" max="100" suffix="%" dense></v-text-field>
                </v-col>
              </v-row>
              <!-- Trailing Stop2 -->
              <v-row no-gutters class="red--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6">{{$t("spotTrading.trailingStopLevel2")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field v-model="trailingStopLevel2" type="number" min="0" max="100" suffix="%" dense></v-text-field>
                </v-col>
              </v-row>
              <!-- Price3 -->
              <v-row no-gutters class="green--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6">{{$t("spotTrading.priceLevel3")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field v-model="priceLevel3" type="number" min="0" max="100" suffix="%" dense></v-text-field>
                </v-col>
              </v-row>
              <!-- Trailing Stop3 -->
              <v-row no-gutters class="red--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6">{{$t("spotTrading.trailingStopLevel3")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field v-model="trailingStopLevel3 " type="number" min="0" max="100" suffix="%" dense></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions class="px-5">
            <v-spacer></v-spacer>
            <v-btn tile small class="blue white--text mb-3" @click="setTrailingStop">{{$t("spotTrading.setStops")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-layout row justify-center>
      <!-- *******************下单确认弹窗******************* -->
      <v-dialog v-model="orderConfirmationDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.orderDetailsDialog")}}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelDialog">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container v-if="orderForm != null">
            <v-row no-gutters>
              <v-col cols="5">{{$t("spotTrading.exchange")}} :</v-col>
              <v-col cols="7">{{ getExchangeNameByCode(orderForm.exchange) }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("spotTrading.symbol")}} :</v-col>
              <v-col cols="7">{{ orderForm.symbol }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("spotTrading.price")}} :</v-col>
              <v-col cols="7">
                <span class="d-custom-flex">
                  <span>{{ formatNum(orderForm.price) }}</span>
                  <span class="ml-3">{{ getQuoteCurrency(orderForm.exchange, orderForm.symbol) }}</span>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("spotTrading.size")}} :</v-col>
              <v-col cols="7">
                <span class="d-custom-flex">
                  <span>{{ orderForm.side == 'open_short' || orderForm.side == 'close_sell' ? '-' : '' }}{{ formatNum(orderForm.size) }}</span>
                  <span class="ml-3">{{ getBaseCurrency(orderForm.exchange, orderForm.symbol) }}</span>
                </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters v-if="'open' == side">
              <v-col cols="5">{{$t("spotTrading.takeProfit")}} :</v-col>
              <v-col cols="7">{{ formatNum(orderForm.takeProfitPrice == 0 ? '--': orderForm.takeProfitPrice) }}</v-col>
            </v-row>
            <v-divider v-if="'open' == side"></v-divider>
            <v-row no-gutters v-if="'open' == side">
              <v-col cols="5">{{$t("spotTrading.stopLoss") + ' 1'}} :</v-col>
              <v-col cols="7">
                <span v-if="'open_long' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 - orderForm.trailingStopLevel1)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else-if="'open_short' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 + orderForm.trailingStopLevel1)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else>--</span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="'open' == side">
              <v-col cols="5">{{$t("spotTrading.stopLoss") + ' 2'}} :</v-col>
              <v-col cols="7">
                <span v-if="'open_long' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 + orderForm.priceLevel2) * (1 - orderForm.trailingStopLevel2)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else-if="'open_short' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 - orderForm.priceLevel2) * (1 + orderForm.trailingStopLevel2)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else>--</span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="'open' == side">
              <v-col cols="5">{{$t("spotTrading.stopLoss") + ' 3'}} :</v-col>
              <v-col cols="7">
                <span v-if="'open_long' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 + orderForm.priceLevel3) * (1 - orderForm.trailingStopLevel3)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else-if="'open_short' == orderForm.side">
                  {{ formatNum((orderForm.price * (1 - orderForm.priceLevel3) * (1 + orderForm.trailingStopLevel3)).toFixed(getDecimalPlace(orderForm.exchange, orderForm.symbol))) }}
                </span>
                <span v-else>--</span>
                <span v-if="orderForm.trailingStopLevel1 == 0 && orderForm.trailingStopLevel2 == 0 && orderForm.trailingStopLevel3 == 0">{{$t("spotTrading.noStopPriceNote")}}</span>
              </v-col>
            </v-row>
            <v-divider v-if="'open' == side"></v-divider>
            <v-row no-gutters>
              <v-col cols="5" class="align-self-sm-center">{{ $t("spotTrading.startTime") }} :</v-col>
              <v-col cols="7">
                <datetime :disabled="orderForm.strategy == 'PP' || orderForm.strategy == 'CPP'" input-id="orderFormSendOrderStartDatetime" v-model="orderForm.sendOrderStartDatetime"
                  class="timeBg" type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone"
                  :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto :min-datetime="pageRefreshDateTime"
                  :class="[(orderForm.strategy == 'PP' || orderForm.strategy == 'CPP') ? 'cursor-none' : '' ]"></datetime>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("spotTrading.orderSide")}}:</v-col>
              <v-col cols="7" :class="[orderSide == 'buy' ?'green--text': 'red--text']">
                {{ side.toUpperCase() }}&nbsp;&nbsp;{{ ('open'==side?('buy'==orderSide?"long":"short"):orderSide).toUpperCase() }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
          <v-card-actions v-if="responsive == 'PC'" class="flex-m pt-0">
            <v-spacer></v-spacer>
            <v-btn tile small class="yellow darken-2 black--text mr-3" @click="showTrailingStopDialog" v-if="'open' == side">
              {{$t("spotTrading.continueEditing")}}
            </v-btn>
            <v-btn tile small class="blue white--text" :loading="submittedOrderFormLoading" @click="submitOrder">
              {{$t("spotTrading.send")}}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="responsive == 'mobile'" class="flex-b">
             <v-spacer></v-spacer>
            <v-btn tile small class="yellow darken-2 black--text ml-3" @click="gotoNextOrder3" v-if="'open' == side">
              {{$t("spotTrading.continueEditing")}}
            </v-btn>
            <v-btn tile small class="blue white--text ml-3" :loading="submittedOrderFormLoading" @click="submitOrder">
              {{$t("spotTrading.send")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <!-- *******************取消未提交订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="deleteUnsubmittedOrderDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.deleteSpotOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="deleteUnsubmittedOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile small class="blue white--text" :loading="deleteUnsubmittedLoading" @click="deleteUnsubmittedOrder">{{$t("spotTrading.yes")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************取消已提交订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="cancelSubmittedOrderDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.spotOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="cancelSubmittedOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text" :loading="cancelSubmittedLoading" @click="cancelSubmittedOrder">{{$t("spotTrading.yes")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************修改定时器的弹窗changeTimeDialog******************* -->
    <v-row>
      <v-dialog v-model="changeTimeDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.changeTime")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeTimeDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <!-- - - - - - 当前时间 - - - - - -->
            <v-row no-gutters class="fw-bold">
              <v-col cols="6">
                {{ $t("spotTrading.currentTime") }} {{ time == 'UTCTime' ? '(UTC)' : '(Local)'}}:
              </v-col>
              <v-col cols="6" class="text-right">
                <span>{{ currentTime }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- - - - - - 发单时间 - - - - - -->
            <div v-show="changeTimeOrder.status=='unsubmitted'">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-center">
                  {{ $t("spotTrading.sendOrderStartTime") }}:
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderSendOrderStartDatetime" v-model="changeTimeOrder.sendOrderStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6" md="6" class="align-self-center">
                  {{ $t("spotTrading.sendOrderEndTime") }}:
                </v-col>
                <v-col cols="3" md="3" class="align-self-center">
                  {{ $t("spotTrading.infinity") }}:
                </v-col>
                <v-col cols="3" md="3">
                  <v-switch small v-model="changeTimeOrder.sendOrderEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.sendOrderEndSwitch">
                <v-col cols="6" md="6">
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderSendOrderEndDatetime" v-model="changeTimeOrder.sendOrderEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.sendOrderStartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
            <!-- - - - - - 止盈时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("spotTrading.takeProfitStartTime") }}:
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderTakeProfitStartTimestamp" v-model="changeTimeOrder.takeProfitStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("spotTrading.takeProfitEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("spotTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch v-model="changeTimeOrder.takeProfitEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.takeProfitEndSwitch">
                <v-col offset-md="6" cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderTakeProfitEndTimestamp" v-model="changeTimeOrder.takeProfitEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.takeProfitStartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
            <!-- - - - - - 保底止损时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("spotTrading.stopLoss1StartTime") }}:
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderStopLoss1StartTimestamp" v-model="changeTimeOrder.stopLoss1StartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("spotTrading.stopLoss1EndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("spotTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch v-model="changeTimeOrder.stopLoss1EndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.stopLoss1EndSwitch">
                <v-col offset-md="6" cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderStopLoss1EndTimestamp" v-model="changeTimeOrder.stopLoss1EndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.stopLoss1StartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
            <!-- - - - - - 止损时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && !changeTimeOrder.beFixedStopLossPrice && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("spotTrading.stopLossStartTime") }}:
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderStopLossStartTimestamp" v-model="changeTimeOrder.stopLossStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("spotTrading.stopLossEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("spotTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch v-model="changeTimeOrder.stopLossEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.stopLossEndSwitch">
                <v-col offset-md="6" cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderStopLossEndTimestamp" v-model="changeTimeOrder.stopLossEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.stopLossStartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
            <!-- - - - - - 固定止损时间 - - - - - -->
            <div v-show="changeTimeOrder.beFixedStopLossPrice && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("spotTrading.fixedStopLossStartTime") }}:
                </v-col>
                <v-col cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderFixedStopLossStartTimestamp" v-model="changeTimeOrder.fixedStopLossStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("spotTrading.fixedStopLossEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("spotTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch v-model="changeTimeOrder.fixedStopLossEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.fixedStopLossEndSwitch">
                <v-col offset-md="6" cols="6" class="text-right">
                  <datetime input-id="changeTimeOrderFixedStopLossEndTimestamp" v-model="changeTimeOrder.fixedStopLossEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.fixedStopLossStartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions class="px-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text mb-3" :loading="changeTimeLoading" @click="submitChangeTimeOrder">
              {{ $t("spotTrading.update") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************编辑订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="changeOrderDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.changeThisSpotOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container class="pt-3 pb-0">
            <h5 class="fw-bold">
              <span v-show="'unsubmitted' == showChangedSpotOrder.status && ('open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side)">{{$t("spotTrading.pendingEntryOrderStatus")}}</span>
              <span v-show="'unsubmitted' == showChangedSpotOrder.status && ('close_buy' == showChangedSpotOrder.side || 'close_sell' == showChangedSpotOrder.side)">{{$t("spotTrading.pendingExitOrderStatus")}}</span>
              <span v-show="'submitted' == showChangedSpotOrder.status">{{$t("spotTrading.unfilledAtExchangesOrderStatus")}}</span>
              <span v-show="'fully_filled' == showChangedSpotOrder.status">{{$t("spotTrading.openPositionsOrderStatus")}}</span>
              <span v-show="'splited' == showChangedSpotOrder.status">{{$t("spotTrading.splitedOrderStatus")}}</span>
            </h5>
            <v-row no-gutters class="mt-3">
              <v-col cols="12" sm="6" md="4">
                {{$t("spotTrading.exchange")}}: {{ getExchangeNameByCode(showChangedSpotOrder.exchange) }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                {{$t("spotTrading.symbol")}}: {{ showChangedSpotOrder.symbol }}
              </v-col>
              <v-col cols="12" sm="6" md="4">
                {{$t("spotTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showChangedSpotOrder.side || 'close_buy' == showChangedSpotOrder.side) ? 'green--text' : 'red--text'">{{ showChangedSpotOrder.side }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- <div class="timeFt">{{$t("spotTrading.changes")}}</div> -->
            <!--******************** 编辑订单 ********************** -->
            <div>
                <!-- v-show="'fully_filled' != showChangedSpotOrder.status && 'splited' != showChangedSpotOrder.status" -->
                <!-- Size -->
                <v-row no-gutters v-show="'unsubmitted' == showChangedSpotOrder.status || 'submitted' == showChangedSpotOrder.status">
                  <v-col cols="6" align-self="center" no-gutters>
                    {{$t("spotTrading.size")}}:
                  </v-col>
                  <v-col cols="5" class="text-right">
                    <span v-if="isEditingSpotOrder">
                      <v-text-field dense single-line v-model="changedSpotOrderForm.size" type="number" :min="getMinSize(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)" :step="getSizeIncrement(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)" :suffix="getBaseCurrency(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)"></v-text-field>
                    </span>
                    <span v-if="!isEditingSpotOrder" class="mt-3">
                      <span>
                        {{ showChangedSpotOrder.side == 'open_short' || showChangedSpotOrder.side == 'close_sell' ? '-' : '' }}{{ formatNum(changedSpotOrderForm.size) }}
                      </span>
                      <span class="ml-2">
                        {{ getBaseCurrency(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol) }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
                <!-- Price -->
                <v-row no-gutters>
                  <v-col cols="6" align-self="center">
                    <span v-show="showChangedSpotOrder.side == 'open_long' || showChangedSpotOrder.side == 'open_short'">{{$t("spotTrading.entryPrice")}}:</span>
                    <span v-show="showChangedSpotOrder.side == 'close_buy' || showChangedSpotOrder.side == 'close_sell'">{{$t("spotTrading.exitPrice")}}:</span>
                  </v-col>
                  <v-col cols="5" class="text-right">
                    <span v-if="isEditingSpotOrder">
                      <v-text-field single-line v-model="changedSpotOrderForm.price" type="number" min="0" :step="getTickSize(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)" :suffix="getQuoteCurrency(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)"></v-text-field>
                    </span>
                    <span v-if="!isEditingSpotOrder" class="mt-3">
                      <span>{{ formatNum(Number(changedSpotOrderForm.price).toFixed(getDecimalPlace(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol))) }}</span>
                      <span class="ml-2">
                        {{ getQuoteCurrency(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol) }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
                <!-- Take Profit -->
                <v-row no-gutters v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                  <v-col cols="6" align-self="center">
                    {{$t("spotTrading.takeProfit")}}:
                  </v-col>
                  <v-col cols="5" class="text-right">
                    <span v-if="isEditingSpotOrder">
                      <v-text-field dense single-line :step="getTickSize(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)" v-model="changedSpotOrderForm.takeProfitPrice" type="number" min="0"
                        id="green_font"></v-text-field>
                    </span>
                    <span v-if="!isEditingSpotOrder" class="mt-3">
                      <span>{{ formatNum(Number(changedSpotOrderForm.takeProfitPrice).toFixed(getDecimalPlace(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol))) }}</span>
                      <span class="ml-2">{{ getQuoteCurrency(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol) }}</span>
                    </span>
                  </v-col>
                </v-row>
            </div>
            <!-- *********************编辑订单计算止损价************* -->
            <div v-show="!changedSpotOrderForm.beFixedStopLossPrice">
              <v-row no-gutters v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <h5 v-show="!isEditingSpotOrder" class="mt-5">
                  {{ $t("spotTrading.estimatedResultsNote") }}:
                </h5>
              </v-row>
              <!-- Trailing Stop1 -->
              <v-row no-gutter class="red--text" v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <v-col cols="6" align-self="center">{{$t("spotTrading.safetyNet")}}</v-col>
                <v-col cols="5" class="text-right px-0">
                  <span v-show="isEditingSpotOrder">
                    <v-text-field dense single-line v-model="changedSpotOrderTrailingStopForm.trailingStopLevel1" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingSpotOrder" class="mt-3">
                    {{ changedSpotOrderFormTrailingStopLevel1Result }}
                  </span>
                </v-col>
              </v-row>
              <!-- Price2 -->
              <v-row no-gutter class="green--text" v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <v-col cols="6" align-self="center">{{$t("spotTrading.priceLevel2")}}</v-col>
                <v-col cols="5" class="text-right px-0">
                  <span v-show="isEditingSpotOrder">
                    <v-text-field dense single-line v-model="changedSpotOrderTrailingStopForm.priceLevel2" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingSpotOrder" class="mt-3">
                    {{ changedSpotOrderFormPriceLevel2Result }}
                  </span>
                </v-col>
              </v-row>
              <!-- Trailing Stop2 -->
              <v-row no-gutter class="red--text" v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <v-col cols="6" align-self="center">{{$t("spotTrading.trailingStopLevel2")}}</v-col>
                <v-col cols="5" class="text-right px-0">
                  <span v-show="isEditingSpotOrder">
                    <v-text-field dense single-line v-model="changedSpotOrderTrailingStopForm.trailingStopLevel2" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingSpotOrder" class="mt-3">
                    {{ changedSpotOrderFormTrailingStopLevel2Result }}
                  </span>
                </v-col>
              </v-row>
              <!-- Price3 -->
              <v-row no-gutter class="green--text" v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <v-col cols="6" align-self="center">{{$t("spotTrading.priceLevel3")}}</v-col>
                <v-col cols="5" class="text-right px-0">
                  <span v-show="isEditingSpotOrder">
                    <v-text-field dense single-line v-model="changedSpotOrderTrailingStopForm.priceLevel3" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingSpotOrder" class="mt-3">
                    {{ changedSpotOrderFormPriceLevel3Result }}
                  </span>
                </v-col>
              </v-row>
              <!-- Trailing Stop3 -->
              <v-row no-gutter class="red--text" v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
                <v-col cols="6" align-self="center" no-gutter>{{$t("spotTrading.trailingStopLevel3")}}</v-col>
                <v-col cols="5" class="text-right px-0" no-gutter>
                  <span v-show="isEditingSpotOrder">
                    <v-text-field dense single-line v-model="changedSpotOrderTrailingStopForm.trailingStopLevel3" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingSpotOrder" class="mt-3">
                    {{ changedSpotOrderFormTrailingStopLevel3Result }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- *********************切换止损比例和固定止损************ -->
            <v-row no-gutter v-show="'open_long' == showChangedSpotOrder.side || 'open_short' == showChangedSpotOrder.side">
              <v-col cols="6">
                <span v-show="isEditingSpotOrder">
                  <v-checkbox class="red--text" v-model="changedSpotOrderForm.beFixedStopLossPrice" :label="$t('spotTrading.beFixedStopLossPrice')"></v-checkbox>
                </span>
                <span v-show="changedSpotOrderForm.beFixedStopLossPrice && !isEditingSpotOrder">
                  {{ $t('spotTrading.fixedStopLossPrice') }}:
                </span>
              </v-col>
              <v-col cols="5" class="text-right px-0 align-self-sm-center">
                <span v-show="changedSpotOrderForm.beFixedStopLossPrice && isEditingSpotOrder">
                  <v-text-field dense single-line v-model="changedSpotOrderForm.fixedStopLossPrice" :step="getTickSize(showChangedSpotOrder.exchange, showChangedSpotOrder.symbol)"  type="number" min="0"
                    id="red_font"></v-text-field>
                </span>
                <span v-show="changedSpotOrderForm.beFixedStopLossPrice && !isEditingSpotOrder" class="mt-3">
                  {{ formatNum(changedSpotOrderForm.fixedStopLossPrice) }}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-5">
            <v-spacer></v-spacer>
            <v-btn tile small v-show="isEditingSpotOrder" class="blue white--text mb-3" @click="isEditingSpotOrder = !isEditingSpotOrder">{{$t("spotTrading.confirm")}}</v-btn>
            <v-btn tile small v-show="!isEditingSpotOrder" class="yellow darken-2 black--text mb-3" @click="isEditingSpotOrder = !isEditingSpotOrder">{{$t("spotTrading.continueEditing")}}</v-btn>
            <v-btn tile small v-show="!isEditingSpotOrder" class="blue white--text ml-3 mb-3" :loading="changeOrderLoading"
              @click="submitChangedSpotOrder">{{$t("spotTrading.update")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************切割订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="splitOrderDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.splitThisSpotOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="closeSplitOrderDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <h5>{{$t("spotTrading.openPositionsOrderStatus")}}</h5>
            <v-row no-gutter>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.exchange")}}: {{ getExchangeNameByCode(showSplitSpotOrder.exchange) }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.symbol")}}: {{ showSplitSpotOrder.symbol }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showSplitSpotOrder.side || 'close_buy' == showSplitSpotOrder.side) ? 'green--text' : 'red--text'">{{ showSplitSpotOrder.side }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-4 font-weight-bold">{{$t("spotTrading.split")}}</h4>
            <!--******************** 编辑分割订单 ********************** -->
            <v-row no-gutter dense>
              <v-col cols="12" sm="6" md="5" align-self="center">
                {{$t("spotTrading.size")}}:
              </v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderForm.size"  type="number" :min="getMinSize(showSplitSpotOrder.exchange, showSplitSpotOrder.symbol)" :step="getSizeIncrement(showSplitSpotOrder.exchange, showSplitSpotOrder.symbol)" :suffix="getBaseCurrency(showSplitSpotOrder.exchange, showSplitSpotOrder.symbol)"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutter dense v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">
                {{$t("spotTrading.takeProfit")}}:
              </v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderForm.takeProfitPrice" :step="getTickSize(showSplitSpotOrder.exchange, showSplitSpotOrder.symbol)" type="number" min="0"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop1 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">{{$t("spotTrading.safetyNet")}}</v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderTrailingStopForm.trailingStopLevel1" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Price2 -->
            <v-row no-gutter dense class="green--text" v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">{{$t("spotTrading.priceLevel2")}}</v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderTrailingStopForm.priceLevel2" type="number" min="0"
                  max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop2 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">{{$t("spotTrading.trailingStopLevel2")}}</v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderTrailingStopForm.trailingStopLevel2" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Price3 -->
            <v-row no-gutter dense class="green--text" v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">{{$t("spotTrading.priceLevel3")}}</v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderTrailingStopForm.priceLevel3" type="number" min="0"
                  max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop3 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitSpotOrder.side || 'open_short' == showSplitSpotOrder.side">
              <v-col cols="12" sm="6" md="5" align-self="center">{{$t("spotTrading.trailingStopLevel3")}}</v-col>
              <v-col cols="12" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitSpotOrderTrailingStopForm.trailingStopLevel3" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-5">
            <v-spacer></v-spacer>
            <v-btn small small class="blue white--text ml-3 mb-3" :loading="splitOrderLoading" @click="splitSpotOrder">{{$t("spotTrading.split")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************退出仓位的订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="exitPositionOrderDialog" persistent max-width="490">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.exitOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="exitPositionOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <h5>{{$t("spotTrading.openPositionsOrderStatus")}}</h5>
            <v-row no-gutter>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.exchange")}}: {{ getExchangeNameByCode(showExitPositionOrder.exchange) }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.symbol")}}: {{ showExitPositionOrder.symbol }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showExitPositionOrder.side || 'close_buy' == showExitPositionOrder.side) ? 'green--text' : 'red--text'">{{ showExitPositionOrder.side }}</span>
              </v-col>
            </v-row>
            <v-row no-gutter>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.size")}}: {{ showExitPositionOrder.size }}
              </v-col>
              <v-col cols="12" sm="4" md="4">
                {{$t("spotTrading.entryPrice")}}: {{ showExitPositionOrder.price }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h5>{{$t("spotTrading.closeOrExit")}}</h5>
            <v-row no-gutter>
              <v-col cols="12" sm="6" md="5" align-self="center">
                {{$t("spotTrading.exitPrice")}}:
              </v-col>
              <v-col cols="12" sm="6" md="3" class="text-right" v-show="isEditingExitPositionOrder">
                <v-text-field dense single-line v-model="exitPositionOrderForm.price" :step="getTickSize(showExitPositionOrder.exchange, showExitPositionOrder.symbol)" type="number" min="0"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="text-right" v-show="isEditingExitPositionOrder">
                <v-btn small outlined @click="getExitPositionBestBidOffer">
                  {{$t("spotTrading.bestBidOffer")}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="5" class="text-right" v-show="!isEditingExitPositionOrder">
                <span class="mt-3">
                  {{ exitPositionOrderForm.price }}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-5">
            <v-spacer></v-spacer>
            <v-btn small tile v-show="isEditingExitPositionOrder" class="blue white--text mb-3" @click="isEditingExitPositionOrder = !isEditingExitPositionOrder">
              {{$t("spotTrading.confirm")}}
            </v-btn>
            <v-btn small tile v-show="!isEditingExitPositionOrder" class="orange white--text mb-3" @click="isEditingExitPositionOrder = !isEditingExitPositionOrder">
              {{$t("spotTrading.change")}}
            </v-btn>
            <v-btn small tile v-show="!isEditingExitPositionOrder" class="blue white--text ml-3 mb-3" :loading="exitPositionOrderLoading"
              @click="submitAddExitSpotOrder">
              {{$t("spotTrading.send")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************修改备注的弹窗******************* -->
    <v-row>
      <v-dialog v-model="changeNoteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("spotTrading.changeNoteTitle")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeNoteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="6">
                <v-select v-model="changeNoteForm.strategy" :items="strategies" required dense></v-select>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-select v-model="changeNoteForm.category" :items="getCategoriesByStrategy(changeNoteForm.strategy)"
                  required dense></v-select>
              </v-col>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn small tile color="blue white--text" :loading="changeNoteLoading" @click="updateStrategyAndCategory">{{$t("common.ok")}}</v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- mobile -->
    <v-container v-if="responsive == 'mobile' && bottomNav =='trading'" class="flex-b grid-list-xs pt-0 pl-0 pr-0 mb-5 pb-5">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pl-0 pt-8 pb-0 mb-0" v-if="nextOrder!='next3'">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="ml-3">
              <v-card flat>
                <v-row class="ml-2">
                  <v-col cols="12" class="pb-0 pr-0">
                    <v-row dense>
                      <v-col cols="1" class="mt-2">
                        <v-card flat v-if="nextOrder=='next1'">
                          <v-icon color="grey" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
                        </v-card>
                        <v-card flat v-if="nextOrder=='next2'">
                          <v-icon color="grey" small @click="nextOrder='next1'">mdi-arrow-left</v-icon>
                        </v-card>
                      </v-col>
                      <v-col cols="5" v-if="nextOrder=='next1'" class="mb-3">
                        <trading-type-change></trading-type-change>
                      </v-col>
                      <!-- <v-col cols="6" v-if="nextOrder=='next1'" class="mx-0 px-0">
                        <v-tabs :show-arrows="false" grow slider-color="lightred" v-model="spotTradingQuoteCurrency">
                          <v-tab @click="symbol='BTC-USDT', spotTradingQuoteCurrency = 'USDT'" href="#USDT">
                            <span>USDT</span>
                          </v-tab>
                          <v-tab @click="symbol='ETHBTC', spotTradingQuoteCurrency = 'BTC'" href="#BTC">
                            <span>CRYPTO</span>
                          </v-tab>
                        </v-tabs>
                      </v-col> -->
                       <!-- <v-col cols="5" v-if="nextOrder=='next1'" class="text-right">
                        <v-btn-toggle v-model="spotTradingQuoteCurrency" borderless>
                          <v-btn value="USDT" @click="symbol='BTC-USDT', spotTradingQuoteCurrency = 'USDT'" width="60px" height="40px">
                            USDT   
                          </v-btn>
                          <v-btn value="BTC" @click="symbol='ETH-BTC', spotTradingQuoteCurrency = 'BTC'" width="60px" height="40px">
                            CRYPTO
                          </v-btn>
                        </v-btn-toggle>
                       </v-col> -->
                       <v-col cols="5" v-if="nextOrder=='next1'" class="text-right">
                        <div v-if="isSetApiKey && accounts != null" class="fs-12">
                          <div v-for="(symbolObject, i) in currentExchangeSpotSymbolInfos" :key="i">
                            <div v-if="symbolObject.symbol == symbol">
                              <span>{{$t("spotTrading.avaliable")}}: </span>
                              <span v-if="accounts != null && getBaseCurrency(exchangeCode, symbol) != ''">{{ formatNum(accounts[getBaseCurrency(exchangeCode, symbol)].avaliable.toFixed(4)) + ' ' + getBaseCurrency(exchangeCode, symbol) }}</span>
                              <span v-else>--</span><br>
                              <span>{{$t("spotTrading.balance")}}: </span>
                              <span v-if="accounts != null && getBaseCurrency(exchangeCode, symbol) != ''">{{ formatNum(accounts[getBaseCurrency(exchangeCode, symbol)].balance.toFixed(4)) + ' ' + getBaseCurrency(exchangeCode, symbol) }}</span>
                              <span v-else>--</span><br>
                              <span>{{$t("spotTrading.avaliable")}}: </span>
                              <span v-if="accounts != null && getQuoteCurrency(exchangeCode, symbol) != ''">{{ accounts[getQuoteCurrency(exchangeCode, symbol)].avaliable.toFixed(4) + ' ' + getQuoteCurrency(exchangeCode, symbol) }}</span>
                              <span v-else>--</span><br>
                              <span>{{$t("spotTrading.balance")}}: </span>
                              <span v-if="accounts != null && getQuoteCurrency(exchangeCode, symbol) != ''">{{ accounts[getQuoteCurrency(exchangeCode, symbol)].balance.toFixed(4) + ' ' + getQuoteCurrency(exchangeCode, symbol) }}</span>
                              <span v-else>--</span><br>
                              <!-- {{$t("common.equity")}}: {{ formatNum(accounts[symbolObject.baseCurrency].equity.toFixed(4)) + ' USDT' }}<br>
                              {{$t("spotTrading.avaliable")}}: {{ formatNum(accounts[symbolObject.baseCurrency].avaliable.toFixed(4)) + ' USDT' }}<br>
                              {{$t("spotTrading.balance")}}: {{ formatNum(accounts[symbolObject.baseCurrency].balance.toFixed(4)) + ' USDT' }} -->
                            </div>
                          </div>
                        </div>
                        <div v-if="!isSetApiKey" class="text-lg-right mx-5 fs-12">
                          <div>
                            {{$t("spotTrading.avaliable")}}: --<br>
                            {{$t("spotTrading.balance")}}: --<br>
                            {{$t("spotTrading.avaliable")}}: --<br>
                            {{$t("spotTrading.balance")}}: --<br>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mx-3 mt-5">
                  <v-col cols="6" class="fs-20">
                    <app-icon :symbol="symbol"></app-icon>
                    <span class="pa-1 ml-3">{{ symbol }}</span>
                  </v-col>
                  <v-col cols="6" class="text-right pr-6">
                    <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].current != null"
                      :class="marketPrice[symbol].current.color">{{ formatNum(marketPrice[symbol].current.price)}}</span>
                    <span v-else>--</span>
                  </v-col>
                </v-row>
                <v-row class="mx-3 mt-5">
                  <v-col cols="12" class="text-center">
                    <span>
                      <sub class="fs-12">{{$t("spotTrading.bid")}} </sub>
                      <span class="fs-36 green--text">
                        <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].bid != null"
                          :class="marketPrice[symbol].bid.color">{{ formatNum(marketPrice[symbol].bid.price)}}</span>
                        <span v-else>--</span>
                      </span>
                      <span class="mx-2 fs-36"> |</span>
                      <sub class="fs-12">{{$t("spotTrading.ask")}} </sub>
                      <span class="fs-36 red--text">
                        <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].ask != null"
                          :class="marketPrice[symbol].ask.color">{{ formatNum(marketPrice[symbol].ask.price)}}</span>
                        <span v-else>--</span>
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mx-3 text-right pr-10">
          <!-- 交易对菜单 -->
          <v-menu offset-y :nudge-width="100" :max-height="300" v-if="nextOrder=='next1' && currentExchangeSpotSymbolInfos != null">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab dark x-small color="lightred black--text">
                <v-icon dense>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(symbolObject, i) in currentExchangeSpotSymbolInfos" :key="i"  @click="gotoSpotOrder(symbolObject.symbol)">
                <router-link class="del-underline" :to="'/trading/spot/' + exchangeCode + '/' + symbolObject.symbol">
                  <v-card flat class="del-underline spot-symbol">
                    <app-icon :symbol="symbolObject.symbol" class="mr-1"></app-icon>
                    {{ symbolObject.symbol }}
                  </v-card>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- *******************手机发送订单的表单******************* -->
          <v-form ref="mobileOrderForm">
            <v-row v-if="nextOrder=='next1'" justify="center" align="center">
              <v-col cols="12" class="text-center">
                <div class="grey--text text--lighten-1 pointer">
                  <span class="pointer fs-20 mr-4" :class="[side == 'open' ? 'fs-36 grey--text darken-3' : '']" @click="changeSide('open')">{{$t("spotTrading.open")}}
                  </span>
                  <span class="pointer font-20 " :class="[side == 'close' ? 'fs-36 grey--text darken-3' : '']" @click="changeSide('close')">{{$t("spotTrading.close")}}</span>
                </div>
              </v-col>
              <v-col cols="12" class="text-center">
                <div class="grey--text text--lighten-1 pointer">
                  <span class="pointer font-16 mr-4" :class="[orderSide == 'buy' ? 'fs-36 teal--text darken-4' : '']"
                    @click="changeOrderSide('buy')">{{$t("spotTrading.buy")}}</span>
                  <span class="pointer font-16" :class="[orderSide == 'sell' ? 'fs-36 red--text' : '']" @click="changeOrderSide('sell')">{{$t("spotTrading.sell")}}</span>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mx-5 mb-0 pb-0">
                <v-select v-model="orderForm.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                  item-text="name" item-value="code" :rules="[v => !!v || $t('spotTrading.requiredMessage.exchangeMsg')]"
                  required @change="changeExchange"></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-select v-model="orderForm.strategy" :items="strategies" :label="$t('common.strategy')"
                  required></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-select v-model="orderForm.category" :items="getCategoriesByStrategy(orderForm.strategy)"
                  :label="$t('common.category')" required></v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mb-12 text-center">
                <v-btn tile block v-if="'open' == side" color="indigo darken-4" depressed class="white--text" @click="nextOrder='next2';takeProfitEnabled = true">
                  {{$t("spotTrading.continue")}}
                </v-btn>
                <v-btn tile block v-if="'close' == side" color="indigo darken-4" depressed class="white--text" @click="nextOrder='next2';takeProfitEnabled = false">
                  {{$t("spotTrading.continue")}}
                </v-btn>
              </v-col>
            </v-row>
            <!-- Next 2 -->
            <v-row v-if="nextOrder=='next2'" justify="center" align="center">
              <v-col cols="6" sm="6" md="6" class="mx-5">
                <v-text-field v-model="orderForm.size" type="number" :min="getMinSize(orderForm.exchange, orderForm.symbol)" :step="getSizeIncrement(orderForm.exchange, orderForm.symbol)" :label="$t('spotTrading.size')"
                  :suffix="getBaseCurrency(orderForm.exchange, orderForm.symbol)" required></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-text-field v-model="orderForm.price" type="number" :step="getTickSize(orderForm.exchange, orderForm.symbol)" min="0" :label="$t('spotTrading.price')"
                  :suffix="getQuoteCurrency(orderForm.exchange, orderForm.symbol)" required></v-text-field>
                <div class="text-xs-left mt-2">{{ $t('spotTrading.requiredMessage.minimumSizeMsg') + getMinSize(orderForm.exchange, orderForm.symbol) + ' ' + getBaseCurrency(orderForm.exchange, orderForm.symbol) }}</div>
                <div class="text-xs-left">{{ $t('spotTrading.requiredMessage.sizeIncrementMsg') + getSizeIncrement(orderForm.exchange, orderForm.symbol) + ' ' + getBaseCurrency(orderForm.exchange, orderForm.symbol) }}</div>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mb-12 text-center mt-5">
                <v-btn tile block :disabled="isDisabledOrderButton" :loading="submitBtnLoading" color="white--text"
                  :class="['long' == buttonSide || 'buy' ==  buttonSide? 'green' : 'red']" @click="mobileJudgeParameters(true)">
                  {{ getOrderButtonSide() }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- Next 3 -->
            <v-row v-if="nextOrder=='next3'" class="mt-5">
              <v-col cols="2" class="mt-8">
                <v-icon color="grey" @click="nextOrder='next2'">mdi-arrow-left</v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="'open' == side">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row justify="center" align="center">
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field v-model="orderForm.takeProfitPrice" type="number" min="0" :label="$t('spotTrading.takeProfit')"></v-text-field>
                      </v-col>
                      <!-- 止损价百分比计划设置 -->
                      <v-col cols="12" sm="6" md="6" v-if="orderForm.category != 'MR'" class="text-center">
                        <v-btn-toggle v-model="planVal">
                          <v-btn text value="0" x-small>{{$t("spotTrading.reset")}}</v-btn>
                          <v-btn text value="1" x-small>{{$t("spotTrading.planA")}}</v-btn>
                          <v-btn text value="2" x-small>{{$t("spotTrading.planB")}}</v-btn>
                          <v-btn text value="3" x-small>{{$t("spotTrading.planC")}}</v-btn>
                          <v-btn text value="4" x-small>{{$t("spotTrading.planD")}}</v-btn>
                          <v-btn text value="5" x-small>{{$t("spotTrading.planE")}}</v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <div v-if="'close' == side" class="px-3">
                      <span class="fs-22">{{$t("spotTrading.cannotSetStopPrice")}}</span>
                    </div>
                    <div v-if="'close' != side">
                      <!-- Trailing Stop1 -->
                      <v-row class="red--text" justify="center" align="center">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field :label="$t('spotTrading.safetyNet')" v-model="trailingStopLevel1"
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Price2 -->
                      <v-row justify="center" align="center" class="green--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field :label="$t('spotTrading.priceLevel2')" v-model="priceLevel2"
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Trailing Stop2 -->
                      <v-row justify="center" align="center" class="red--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field :label="$t('spotTrading.trailingStopLevel2')" v-model="trailingStopLevel2"
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Price3 -->
                      <v-row justify="center" align="center" class="green--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field :label="$t('spotTrading.priceLevel3')" v-model="priceLevel3"
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Trailing Stop3 -->
                      <v-row justify="center" align="center" class="red--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field :label="$t('spotTrading.trailingStopLevel3')" v-model="trailingStopLevel3 "
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row justify="center" align="center">
                      <v-col cols="6" sm="6" md="6" class="py-0 my-0 text-center">
                        <v-btn tile block class="indigo darken-4 white--text" @click="setTrailingStop">
                          {{$t("spotTrading.continue")}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <!-- <v-row class="symbol-fixed " v-if="nextOrder=='next1'">
        <v-col cols="12" sm="12" md="12" xs="12" class="pb-0 px-0">
          <v-card :class="[darkMode?'grey darken-2':'']">
            <div class="d-flex py-3 px-5 fs-20 justify-center" v-if="exchangeSupportedSpotSymbols != null">
              <div class="px-1" :class="[symbolObject.supported ? '' : 'cursor-none']" v-for="(symbolObject, i) in exchangeSupportedSpotSymbols[exchangeCode]"
                :key="i" v-if="symbolObject.quoteCurrency == spotTradingQuoteCurrency">
                <div @click="gotoSpotOrder(symbolObject.symbol)" :class="[symbolObject.symbol == symbol ? 'icon-red' : 'icon-grey']">
                  <app-icon :symbol="symbolObject.symbol"></app-icon>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container v-if="responsive == 'mobile' && bottomNav =='orders'" class="flex-b pl-0 mt-5 pr-0 mb-5 pb-5">
      <v-row class="mt-5 mx-3" no-gutters>
       <v-col cols="1">
          <v-menu offset-y :nudge-width="100" :max-height="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab dark x-small color="lightred black--text">
                <v-icon dense>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- 第一页 -->
              <v-list-item @click="orders='pendingEntryOrders'">
                <v-badge color="lightred" :value="pendingEntryOrdersTotalElements" class="mt-2">
                  <template v-slot:badge v-if=" pendingEntryOrdersTotalElements > 0">
                    <span class="black--text">{{pendingEntryOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("spotTrading.pendingEntryOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 第二页 -->
              <v-list-item @click="orders='pendingExitOrders'">
                <v-badge color="lightred" :value="pendingExitOrdersTotalElements">
                  <template v-slot:badge v-if=" pendingExitOrdersTotalElements > 0">
                    <span class="black--text">{{pendingExitOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("spotTrading.pendingExitOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 第三页 -->
              <v-list-item @click="orders='submittedExchangeOrders'">
                <v-list-item-title class="fs-14">
                  {{$t("spotTrading.submittedExchangeOrders")}}
                </v-list-item-title>
              </v-list-item>
              <!-- 第四页 -->
              <v-list-item @click="orders='unfilledExchangeOrders'">
                <v-badge color="lightred" :value="unfilledExchangeOrdersTotalElements">
                  <template v-slot:badge v-if=" unfilledExchangeOrdersTotalElements > 0">
                    <span class="black--text">{{unfilledExchangeOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("spotTrading.unfilledExchangeOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 第五页 -->
              <v-list-item @click="orders='filledExchangeOrders'">
                <v-list-item-title class="fs-14">
                  {{$t("spotTrading.filledExchangeOrders")}}
                </v-list-item-title>
              </v-list-item>
              <!-- 第六页 -->
              <v-list-item @click="orders='openPositions'">
                <v-badge color="lightred" :value="openPositionsOrdersTotalElements">
                  <template v-slot:badge v-if=" openPositionsOrdersTotalElements > 0">
                    <span class="black--text">{{openPositionsOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("spotTrading.openPositions")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 第七页 -->
              <v-list-item @click="orders='openPositionsAtExchanges'">
                <v-badge color="lightred" :value="openPositionsAtExchanges.length">
                  <template v-slot:badge v-if="openPositionsAtExchanges.length > 0">
                    <span class="fs-12 black--text">{{openPositionsAtExchanges.length}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("spotTrading.openPositionsAtExchanges")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="8" class="align-self-sm-center my-2 ml-3">
          {{formatStr(orders)}}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="1">
          <v-btn fab dark x-small color="lightred black--text" title="Refresh" @click="refreshAllSpotOrders">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!--******************************** 手机第一页 ********************************-->
      <v-row v-if="orders=='pendingEntryOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(pendingEntryOrder, i) in pendingEntryOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(pendingEntryOrder.exchangeName)">{{ pendingEntryOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ pendingEntryOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="pendingEntryOrder.side == 'open_long' || pendingEntryOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="pendingEntryOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingEntryOrder.side == 'open_short'|| pendingEntryOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(pendingEntryOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(pendingEntryOrder.price.toFixed(getDecimalPlace(pendingEntryOrder.exchange, pendingEntryOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    <span v-if="pendingEntryOrder.takeProfitPrice == 0">--</span>
                    <span v-else-if="pendingEntryOrder.side == 'open_long'">{{ ((pendingEntryOrder.takeProfitPrice / pendingEntryOrder.price - 1) * 100).toFixed(2) + '%' }}</span>
                    <span v-else-if="pendingEntryOrder.side == 'open_short'">{{ ((pendingEntryOrder.price / pendingEntryOrder.takeProfitPrice - 1) * 100).toFixed(2) + '%' }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.safetyNet")}}
                  </span>
                  <span class="float-right">{{ (pendingEntryOrder.trailingStopLevel1 * 100).toFixed(2) + '%' }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.stopLoss")}}
                  </span>
                  <span class="float-right">
                     {{ (pendingEntryOrder.trailingStopLevel1 * 100).toFixed(2) + '%' }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.effectiveTime")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(pendingEntryOrder.sendOrderStartTimestamp) }}
                  </span>
                  <span class="float-right"> - </span>
                  <span class="float-right">
                    {{ -1 == pendingEntryOrder.sendOrderEndTimestamp ? $t('spotTrading.infinity') : formatDate(pendingEntryOrder.sendOrderEndTimestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(pendingEntryOrder.strategy)}"></span>
                    {{ pendingEntryOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right">
                    {{ pendingEntryOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer ml-3" @click="copyToClipboard(pendingEntryOrder.id)">mdi-content-copy</v-icon>
                    <v-icon color="orange" size="20" class="pointer ml-3" @click="showChangeOrderDialog(pendingEntryOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="blue" size="20" class="pointer ml-3" @click="showChangeTimeDialog(pendingEntryOrder)"
                      title="Time">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-3" @click="showChangeNoteDialog('pendingEntry', pendingEntryOrder)"
                      :title="$t('common.strategy')">icon icon-s1</v-icon>
                    <v-icon color="red" size="20" class="pointer ml-3" @click="deleteUnsubmittedOrderById(pendingEntryOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="pendingEntryLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="pendingEntryOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingEntryOrdersPageNo"
                :length="pendingEntryOrdersTotalPage" :total-visible="6" @input="queryPendingEntryOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!pendingEntryLoading  && pendingEntryOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第二页 ********************************-->
      <v-row v-if="orders=='pendingExitOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(pendingExitOrder, i) in pendingExitOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(pendingExitOrder.exchangeName)">{{ pendingExitOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ pendingExitOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="pendingExitOrder.side == 'open_long' || pendingExitOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="pendingExitOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingExitOrder.side == 'open_short'|| pendingExitOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(pendingExitOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(pendingExitOrder.price.toFixed(getDecimalPlace(pendingExitOrder.exchange, pendingExitOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1" :class="currentTimestamp >= pendingExitOrder.sendOrderStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                    {{$t("spotTrading.effectiveTime")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(pendingExitOrder.sendOrderStartTimestamp) }}
                  </span>
                  <span class="float-right"> - </span>
                  <span class="float-right">
                    {{ -1 == pendingExitOrder.sendOrderEndTimestamp ? $t('spotTrading.infinity') : formatDate(pendingExitOrder.sendOrderEndTimestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(pendingExitOrder.strategy)}">
                    {{ pendingExitOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right">
                    {{ pendingExitOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer ml-3" @click="copyToClipboard(pendingExitOrder.id)">mdi-content-copy</v-icon>
                    <v-icon color="orange" size="20" class="pointer ml-3" @click="showChangeOrderDialog(pendingExitOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="blue" size="20" class="pointer ml-3" @click="showChangeTimeDialog(pendingExitOrder)"
                      title="Time">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-3" @click="showChangeNoteDialog('pendingExit', pendingExitOrder)"
                      :title="$t('common.strategy')">icon icon-s1</v-icon>
                    <v-icon color="red" size="20" class="pointer ml-3" @click="deleteUnsubmittedOrderById(pendingExitOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="pendingExitLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="pendingExitOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingExitOrdersPageNo"
                :length="pendingExitOrdersTotalPage" :total-visible="6" @input="queryPendingExitOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!pendingExitLoading  && pendingExitOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第三页 ********************************-->
      <v-row v-if="orders=='submittedExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(submittedOrder, i) in submittedOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(submittedOrder.exchangeName)">{{ submittedOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ submittedOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="submittedOrder.side == 'open_long' || submittedOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="submittedOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (submittedOrder.side == 'open_short'|| submittedOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(submittedOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(submittedOrder.price.toFixed(getDecimalPlace(submittedOrder.exchange, submittedOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(submittedOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.status")}}
                  </span>
                  <span class="float-right" v-status="submittedOrder.status"></span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(submittedOrder.strategy)}">
                    {{ submittedOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right">
                    {{ submittedOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(submittedOrder.spotOrderId)"
                      :title="submittedOrder.spotOrderId">mdi-content-copy</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="submittedExchangeOrdersLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="submittedOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="submittedOrdersPageNo"
                :length="submittedOrdersTotalPage" :total-visible="6" @input="querySubmittedOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!submittedExchangeOrdersLoading  && submittedOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第四页 ********************************-->
      <v-row v-if="orders=='unfilledExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(unfilledExchangeOrder, i) in unfilledExchangeOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(unfilledExchangeOrder.exchangeName)">{{ unfilledExchangeOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ unfilledExchangeOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="unfilledExchangeOrder.side == 'open_long' || unfilledExchangeOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="unfilledExchangeOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (unfilledExchangeOrder.side == 'open_short'|| unfilledExchangeOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(unfilledExchangeOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(unfilledExchangeOrder.price.toFixed(getDecimalPlace(unfilledExchangeOrder.exchange, unfilledExchangeOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(unfilledExchangeOrder.takeProfitPrice > 0 ? unfilledExchangeOrder.takeProfitPrice.toFixed(getDecimalPlace(unfilledExchangeOrder.exchange, unfilledExchangeOrder.symbol)) : '--') }}
                    <span> / </span>
                    <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.takeProfitPrice > 0 && unfilledExchangeOrder.takeProfitPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.takeProfitPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(unfilledExchangeOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.safetyNet")}}
                  </span>
                  <span class="float-right">
                    <span>{{ formatNum(unfilledExchangeOrder.stopLossPrice1 > 0 ? unfilledExchangeOrder.stopLossPrice1.toFixed(getDecimalPlace(unfilledExchangeOrder.exchange, unfilledExchangeOrder.symbol)) : '--') }}</span>
                    <span> / </span>
                    <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.stopLossPrice1 < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.stopLossPrice1 > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(unfilledExchangeOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.stopLoss")}}
                    <span v-if="!unfilledExchangeOrder.beFixedStopLossPrice">({{$t("common.trailing")}})</span>
                    <span v-if="unfilledExchangeOrder.beFixedStopLossPrice">({{$t("common.fixed")}})</span>
                  </span>
                  <span class="float-right">
                    <span v-if="!unfilledExchangeOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(unfilledExchangeOrder.stopLossPrice > 0 ? unfilledExchangeOrder.stopLossPrice.toFixed(getDecimalPlace(unfilledExchangeOrder.exchange, unfilledExchangeOrder.symbol)) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.stopLossPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.stopLossPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(unfilledExchangeOrder) }}</span>
                    </span>
                    <span v-if="unfilledExchangeOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(unfilledExchangeOrder.fixedStopLossPrice > 0 ? unfilledExchangeOrder.fixedStopLossPrice.toFixed(getDecimalPlace(unfilledExchangeOrder.exchange, unfilledExchangeOrder.symbol)) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.fixedStopLossPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.fixedStopLossPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(unfilledExchangeOrder) }}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(unfilledExchangeOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(unfilledExchangeOrder.strategy)}">
                    {{ unfilledExchangeOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="unfilledExchangeOrder.bgColor">
                    {{ unfilledExchangeOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(unfilledExchangeOrder.spotOrderId)"
                      :title="unfilledExchangeOrder.spotOrderId">mdi-content-copy</v-icon>
                    <v-icon color="orange" size="20" class="pointer ml-3" @click="showChangeOrderDialog(unfilledExchangeOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="red" size="20" class="pointer ml-3" @click="deleteSubmittedOrderById(unfilledExchangeOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="unfilledAtExchangesLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="unfilledExchangeOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="unfilledExchangeOrdersPageNo"
                :length="unfilledExchangeOrdersTotalPage" :total-visible="6" @input="queryUnfilledExchangeOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!unfilledAtExchangesLoading  && unfilledExchangeOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第五页 ********************************-->
      <v-row v-if="orders=='filledExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(filledExchangeOrder, i) in filledExchangeOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(filledExchangeOrder.exchangeName)">{{ filledExchangeOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ filledExchangeOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="filledExchangeOrder.side == 'open_long' || filledExchangeOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="filledExchangeOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (filledExchangeOrder.side == 'open_short'|| filledExchangeOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(filledExchangeOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(filledExchangeOrder.price.toFixed(getDecimalPlace(filledExchangeOrder.exchange, filledExchangeOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(filledExchangeOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.status")}}
                  </span>
                  <span class="float-right" v-status="filledExchangeOrder.status"></span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(filledExchangeOrder.strategy)}">
                    {{ filledExchangeOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="filledExchangeOrder.bgColor">
                    {{ filledExchangeOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(filledExchangeOrder.spotOrderId)"
                      :title="filledExchangeOrder.spotOrderId">mdi-content-copy</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="filledExchangeOrdersLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="filledExchangeOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="filledExchangeOrdersPageNo"
                :length="filledExchangeOrdersTotalPage" :total-visible="6" @input="queryFilledExchangeOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!filledExchangeOrdersLoading  && filledExchangeOrders.length <= 0">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第六页 ********************************-->
      <v-row v-if="orders=='openPositions'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="4" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="4" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('spotTrading.symbol')" :items="spotSymbols" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(openPositionsOrder, i) in openPositionsOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(openPositionsOrder.exchangeName)">{{ openPositionsOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ openPositionsOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="openPositionsOrder.side == 'open_long' || openPositionsOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="openPositionsOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (openPositionsOrder.side == 'open_short'|| openPositionsOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(openPositionsOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsOrder.price.toFixed(getDecimalPlace(openPositionsOrder.exchange, openPositionsOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsOrder.takeProfitPrice > 0 ? openPositionsOrder.takeProfitPrice.toFixed(getDecimalPlace(openPositionsOrder.exchange, openPositionsOrder.symbol)) : '--') }}
                    <span> / </span>
                    <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.takeProfitPrice > 0 && openPositionsOrder.takeProfitPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.takeProfitPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(openPositionsOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.safetyNet")}}
                  </span>
                  <span class="float-right">
                    <span>{{ formatNum(openPositionsOrder.stopLossPrice1 > 0 ? openPositionsOrder.stopLossPrice1.toFixed(getDecimalPlace(openPositionsOrder.exchange, openPositionsOrder.symbol)) : '--') }}</span>
                    <span> / </span>
                    <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.stopLossPrice1 < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.stopLossPrice1 > openPositionsOrder.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(openPositionsOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.stopLoss")}}
                  </span>
                  <span v-if="!openPositionsOrder.beFixedStopLossPrice">({{$t("common.trailing")}})</span>
                  <span v-if="openPositionsOrder.beFixedStopLossPrice">({{$t("common.fixed")}})</span>
                  <span class="float-right">
                    <span v-if="!openPositionsOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(openPositionsOrder.stopLossPrice > 0 ? openPositionsOrder.stopLossPrice.toFixed(getDecimalPlace(openPositionsOrder.exchange, openPositionsOrder.symbol)) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.stopLossPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.stopLossPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(openPositionsOrder) }}</span>
                    </span>
                    <span v-if="openPositionsOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(openPositionsOrder.fixedStopLossPrice > 0 ? openPositionsOrder.fixedStopLossPrice.toFixed(getDecimalPlace(openPositionsOrder.exchange, openPositionsOrder.symbol)) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.fixedStopLossPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.fixedStopLossPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(openPositionsOrder) }}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.takeProfitEffectiveTime")}}
                  </span>
                  <span class="float-right">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" :class="currentTimestamp >= openPositionsOrder.takeProfitStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                            {{formatDate(openPositionsOrder.takeProfitStartTimestamp)}}
                          </span>
                        </template>
                        <span>{{formatDate(openPositionsOrder.takeProfitStartTimestamp)}}</span>
                        <span>-</span>
                        <span>
                          {{ -1 == openPositionsOrder.takeProfitEndTimestamp ? $t('spotTrading.infinity') : formatDate(openPositionsOrder.takeProfitEndTimestamp)}}
                        </span>
                      </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.snEffectiveTime")}}
                  </span>
                  <span class="float-right">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.stopLoss1StartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.stopLoss1StartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.stopLoss1StartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.stopLoss1EndTimestamp ? $t('spotTrading.infinity') : formatDate(openPositionsOrder.stopLoss1EndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.stopLossEffectiveTime")}}
                  </span>
                  <span class="float-right" v-show="!openPositionsOrder.beFixedStopLossPrice">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.stopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.stopLossStartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.stopLossStartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.stopLossEndTimestamp ? $t('spotTrading.infinity') : formatDate(openPositionsOrder.stopLossEndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                  <span class="float-right" v-show="openPositionsOrder.beFixedStopLossPrice">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" :class="currentTimestamp >= openPositionsOrder.fixedStopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                            {{formatDate(openPositionsOrder.fixedStopLossStartTimestamp)}}
                          </span>
                        </template>
                        <span>{{formatDate(openPositionsOrder.fixedStopLossStartTimestamp)}}</span>
                        <span>-</span>
                        <span>
                          {{ -1 == openPositionsOrder.fixedStopLossEndTimestamp ? $t('spotTrading.infinity') : formatDate(openPositionsOrder.fixedStopLossEndTimestamp)}}
                        </span>
                      </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(openPositionsOrder.strategy)}">
                    {{ openPositionsOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="openPositionsOrder.bgColor">
                    {{ openPositionsOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" size="20" class="pointer" @click="copyToClipboard(openPositionsOrder.spotOrderId)"
                      :title="openPositionsOrder.spotOrderId">mdi-content-copy</v-icon>
                    <v-icon color="orange" size="20" class="pointer ml-3" title="Edit" @click="showChangeOrderDialog(openPositionsOrder)">mdi-pencil</v-icon>
                    <v-icon color="green" size="20" class="pointer ml-3" title="Split" @click="showSplitOrderDialog(openPositionsOrder)">mdi-content-cut</v-icon>
                    <v-icon color="blue" size="20" class="pointer ml-3" title="Time" @click="showChangeTimeDialog(openPositionsOrder)">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-2" size="20" class="pointer ml-3" @click="showChangeNoteDialog('openPositions', openPositionsOrder)">icon icon-s1</v-icon>
                    <v-icon color="red" size="20" class="pointer ml-3" title="Close" @click="showExitPositionOrderDialog(openPositionsOrder)">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="openPositionsLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="openPositionsOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="openPositionsOrdersPageNo"
                :length="openPositionsOrdersTotalPage" :total-visible="6" @input="queryOpenPositionsOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!openPositionsLoading  && openPositionsOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第七页 ********************************-->
      <v-row v-if="orders=='openPositionsAtExchanges'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-14" v-model="openPositionsExchangeSearch" :items="exchanges" :label="$t('spotTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-14" v-model="openPositionsCurrencySearch" :label="$t('assets.currency')" :items="currencies" clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(openPositionsAtExchange, i) in openPositionsAtExchanges" :key="i" class="mt-2 my-3 mx-3"
              v-show="!openPositionsAtExchangesLoading">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(openPositionsAtExchange.exchange)">{{ openPositionsAtExchange.exchange }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("assets.currency")}}
                  </span>
                  <span class="float-right">
                    {{ openPositionsAtExchange.currency }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.side")}}
                  </span>
                  <span class="float-right" :class="openPositionsAtExchange.side == 'Long' || openPositionsAtExchange.side == 'buy' ? 'green--text' : 'red--text'">
                    {{ openPositionsAtExchange.side }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsAtExchange.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsAtExchange.price) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("spotTrading.value")}}
                  </span>
                  <span class="float-right" :title="openPositionsAtExchange.value" :class="openPositionsAtExchange.value == 0 ? '' : openPositionsAtExchange.value > 0 ? 'green--text' : 'red--text' ">
                    {{ openPositionsAtExchange.value < 0 ? '(' : '' }}
                    {{ openPositionsAtExchange.value < 0 ? ('$' + formatNum(-openPositionsAtExchange.value.toFixed(4))) : ('$' + formatNum(openPositionsAtExchange.value.toFixed(4))) }}
                    {{ openPositionsAtExchange.value < 0 ? ')' : '' }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="openPositionsAtExchangesLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!openPositionsAtExchangesLoading && openPositionsAtExchanges <= 0">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('spotTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="responsive == 'mobile'" class="flex-b pl-0 mt-5 pr-0 mb-10 pb-5">
      <trading-candle-chart  v-show="bottomNav =='trading'" :exchange="exchangeCode" :tradingCategory="'spot'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.exchange, orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-candle-chart>
      <v-divider v-show="bottomNav =='trading'" class="py-3"></v-divider>
      <trading-heikin-ashi-chart v-show="bottomNav =='trading'" :exchange="exchangeCode" :tradingCategory="'spot'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.exchange, orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-heikin-ashi-chart>
      <v-divider v-show="bottomNav =='trading'" class="py-3"></v-divider>
      <trading-renko-chart v-show="bottomNav =='trading'" ref='tradingRenkoChart' :strategy="orderForm.strategy" :symbol="orderForm.symbol" :tradingCategory="'spot'" :childComponent="childComponent" :takeProfitPrice="renkoChartTakeProfitPrice" :stopLossPrice="renkoChartStopLossPrice" @changeRenkoStrategy="receiveChangeRenkoStrategy" @transferPrice="receiveRenkoPrice" @resetTakeAndStopPrice="receiveResetTakeAndStopPrice" ></trading-renko-chart>
      <asset-summary v-show="bottomNav =='summary'" ref='summary' :hideNonePositions="hideNonePositions" ></asset-summary>
    </v-container>
    <v-bottom-navigation grow v-model="bottomNav" fixed v-if="responsive == 'mobile'" class="mt-4 text-center">
      <v-btn color="lightred" text tile x-large value="trading" class="mx-5">
        <span>{{$t("spotTrading.trading")}}</span>
      </v-btn>
      <v-btn color="lightred" text tile x-large value="orders">
        <span>{{$t("spotTrading.orders")}}</span>
      </v-btn>
      <v-btn color="lightred" text tile x-large value="summary" class="mx-3">
        <span>{{$t("common.summary")}}</span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
  import Vue from 'vue'
  import config from '@/config.js'
  import { mapGetters} from "vuex";
  import OkexSpotMarketPrice from "@/components/marketPrice/OkexSpotMarketPrice.vue";
  import FtxSpotMarketPrice from "@/components/marketPrice/FTXSpotMarketPrice.vue";
  import { setInterval, clearInterval } from 'timers';
  import AppIcon from "@/components/Icon.vue";
  import PortfolioValue from "@/components/PortfolioValue.vue";
  import AssetsTrendLineChart from "@/highcharts/AssetsTrendLineChart.vue";
  import TradingTypeChange from "@/components/trading/TradingTypeChange.vue";
  import AssetSummary from "@/components/summary/SpotAssetSummary.vue";
  import TradingRenkoChart from '@/highcharts/TradingRenkoChart.vue';
  import TradingCandleChart from '@/highcharts/TradingCandleChart.vue';
  import TradingHeikinAshiChart from '@/highcharts/TradingHeikinAshiChart.vue';
  import ChangePercentage from "@/components/marketPrice/ChangePercentage.vue";
  import SearchCoin from '@/components/search/SearchCoin.vue';
  export default {
    data() {
      return {
        exchangeCode: this.$route.params.exchangeCode,
        symbol: this.$route.params.symbol,
        symbols: [],
        // 默认选中USDT
        spotTradingQuoteCurrency: 'USDT',
        tab: 'pendingEntryOrders',
        bottomNav: "trading",
        quoteCurrency: null,
        baseCurrency: null,
        fullScreen: false,
        mini: false,
        nextOrder: "next1",
        orders: 'openPositions',
        priceString: '0.0',
        sizeString: '0.0',
        slippageString: '0.0',
        takeProfitString: '0.0',
        stopLossString: '0.0',
        // 发送的订单表单
        orderForm: {
          exchange: null,
          symbol: null,
          side: 'open_long',
          orderType: 'limit',
          price: 0,
          size: 0,
          strategy: null,
          category: 'TP',
          takeProfitPrice: 0,
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0,
          sendOrderStartDatetime: '',
          sendOrderStartTimestamp: 0
        },
        submitBtnLoading: false,
        pendingEntryOrdersHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.stopLoss'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.effectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        pendingExitOrdersHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.effectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        submittedOrderHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.status')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        unfilledExchangeOrdersHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.stopLoss'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        filledExchangeOrdersHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.status')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        openPositionsOrdersHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.stopLoss'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.snEffectiveTime')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.stopLossEffectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.action')
          }
        ],
        openPositionsAtExchangesHeader: [{
            sortable: false,
            text: this.$t('spotTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('assets.currency')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.side')
          },
          {
            sortable: false,
            text: this.$t('spotTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('spotTrading.value'),
            align: 'end'
          },
        ],
        isSetApiKey: true,
        accounts: null,
        // 每页查询的数量
        pageSize: 10,
        // 订单搜索
        orderSearch: {
          exchange: null,
          symbol: null,
          side: null,
          strategy: null,
          category: null
        },
        // 放置的进入订单
        pendingEntryOrders: [],
        pendingEntryOrdersTotalElements: 0,
        pendingEntryOrdersPageNo: 1,
        pendingEntryOrdersTotalPage: 0,
        pendingEntryOrdersExchangeSearch: null,
        pendingEntryOrdersSymbolSearch: null,
        // 放置的退出订单
        pendingExitOrders: [],
        pendingExitOrdersTotalElements: 0,
        pendingExitOrdersPageNo: 1,
        pendingExitOrdersTotalPage: 0,
        pendingExitOrdersExchangeSearch: null,
        pendingExitOrdersSymbolSearch: null,
        // 已提交到交易所的订单
        submittedOrders: [],
        submittedOrdersTotalElements: 0,
        submittedOrdersPageNo: 1,
        submittedOrdersTotalPage: 0,
        submittedOrdersExchangeSearch: null,
        submittedOrdersSymbolSearch: null,
        // 未成交的订单
        unfilledExchangeOrders: [],
        unfilledExchangeOrdersTotalElements: 0,
        unfilledExchangeOrdersPageNo: 1,
        unfilledExchangeOrdersTotalPage: 0,
        unfilledExchangeOrdersExchangeSearch: null,
        unfilledExchangeOrdersSymbolSearch: null,
        // 已成交的订单
        filledExchangeOrders: [],
        filledExchangeOrdersTotalElements: 0,
        filledExchangeOrdersPageNo: 1,
        filledExchangeOrdersTotalPage: 0,
        filledExchangeOrdersExchangeSearch: null,
        filledExchangeOrdersSymbolSearch: null,
        // 已持仓的订单
        openPositionsOrders: [],
        openPositionsOrdersTotalElements: 0,
        openPositionsOrdersPageNo: 1,
        openPositionsOrdersTotalPage: 0,
        openPositionsOrdersExchangeSearch: null,
        openPositionsOrdersSymbolSearch: null,
        // 交易所持仓的货币
        openPositionsAtExchanges: [],
        openPositionsAtExchangesPagination: {
          descending: false,
          page: 1,
          rowsPerPage: 10,
        },
        openPositionsAtExchangesTotalElements: 0,
        openPositionsAtExchangesTotalPage: 0,
        openPositionsExchangeSearch: null,
        openPositionsCurrencySearch: null,
        stopLossEnabled: true,
        takeProfitEnabled: true,
        side: "open",
        orderSide: "buy",
        buttonSide: 'long',
        // 价格间隙过大的弹出框
        largePriceGapDialog: false,
        getOrdersTimers: null,
        orderConfirmationDialog: false,
        deleteUnsubmittedOrderDialog: false,
        cancelSubmittedOrderDialog: false,
        unsubmittedOrdersId: null,
        submittedOrdersId: null,
        submittedOrderFormLoading: false,
        deleteUnsubmittedLoading: false,
        cancelSubmittedLoading: false,
        // 修改订单弹框
        changeOrderDialog: false,
        changeOrderLoading: false,
        // 修改定时器弹框
        changeTimeDialog: false,
        changeTimeLoading: false,
        // 设置止损价
        trailingStopDialog: false,
        trailingStopLevel1: 0,
        trailingStopLevel2: 0,
        trailingStopLevel3: 0,
        priceLevel2: 0,
        priceLevel3: 0,
        // 显示的修改的现货订单
        showChangedSpotOrder: {},
        // 修改的现货订单
        changedSpotOrderForm: {},
        // 修改的现货止损价订单
        changedSpotOrderTrailingStopForm: {
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0
        },
        // 是否正在编辑订单
        isEditingSpotOrder: true,
        trailingStopLevelRules: [
          val => val < 0 && 'Invalid parameter: ' + val + '%',
          val => val > 100 && 'Invalid parameter: ' + val + '%'
        ],
        // 分割订单弹框
        splitOrderDialog: false,
        splitOrderLoading: false,
        // 显示的分割的现货订单
        showSplitSpotOrder: {},
        // 分割的现货订单
        splitSpotOrderForm: {},
        // 分割的现货止损价订单
        splitSpotOrderTrailingStopForm: {
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0
        },
        // 退出仓位的现货订单弹框
        exitPositionOrderDialog: false,
        // 是否正在编辑退出仓位的订单
        isEditingExitPositionOrder: true,
        // 提交退出仓位订单的加载中
        exitPositionOrderLoading: false,
        // 显示的退出仓位的现货订单
        showExitPositionOrder: {},
        // 退出仓位的现货订单
        exitPositionOrderForm: {
          price: 0
        },
        // 修改备注的弹框
        changeNoteDialog: false,
        // 修改备注加载中
        changeNoteLoading: false,
        // 修改备注参数
        changeNoteForm: {
          orderStatus: null,
          order: null,
          id: null,
          strategy: null,
          category: null
        },
        // 止损计划
        planVal: "1",
        currentTimeTimer: null,
        currentTime: null,
        currentTimestamp: 0,
        changeTimeOrder: {
          id: null,
          spotOrderId: null,
          side: null,
          status: null,
          beFixedStopLossPrice: false,
          sendOrderEndSwitch: false,
          takeProfitEndSwitch: false,
          stopLoss1EndSwitch: false,
          stopLossEndSwitch: false,
          fixedStopLossEndSwitch: false,
          timestamp: null,
          sendOrderStartTimestamp: '',
          sendOrderEndTimestamp: '',
          takeProfitStartTimestamp: '',
          takeProfitEndTimestamp: '',
          stopLoss1StartTimestamp: '',
          stopLoss1EndTimestamp: '',
          stopLossStartTimestamp: '',
          stopLossEndTimestamp: '',
          fixedStopLossStartTimestamp: '',
          fixedStopLossEndTimestamp: ''
        },
        plResultSymbol: "",
        plResult: "",
        environment: config.environment,
        isRefresh: false,
        // 第一页加载状态
        pendingEntryLoading: false,
        // 第二页加载状态
        pendingExitLoading: false,
        // 第三页加载状态
        submittedExchangeOrdersLoading: false,
        // 第四页加载状态
        unfilledAtExchangesLoading: false,
        // 第五页加载状态
        filledExchangeOrdersLoading: false,
        // 第六页加载状态
        openPositionsLoading: false,
        // 第七页加载状态
        openPositionsAtExchangesLoading: false,
        // 价格可以变化
        priceIsChange: true,
        // 止盈价格变动次数
        formProfitPriceCount: 0,
        // 隐藏未持仓货币
        hideNonePositions: false,
        // 显示的子组件
        childComponent: 'candle',
        // Renko图止盈价
        renkoChartTakeProfitPrice: null,
        // Renko图止损价
        renkoChartStopLossPrice: null,
      }
    },
    computed: {
      ...mapGetters(['exchanges', 'spotSymbols', 'exchangeSupportedSpotSymbols', 'spotSymbolInfos', 'darkMode', 'marketPrice', 'time', 'responsive', 'orderCategories', 'orderStrategies', 'strategies', 'strategyColors', 'webSocketData', 'webSocketDataIndex', 'networkStatus', 'currencies']),
      // 读市场价格的参数
      exchange() {
        const exchange = {
          exchangeCode: this.exchangeCode,
          tradingCategory: 'spot'
        }
        return exchange;
      },
      currentTimeZone() {
        if ('UTCTime' == this.time) {
          return 'UTC';
        } else {
          return 'local';
        }
      },
      pageRefreshDateTime: {
        get: function() {
          return formatISO8601Time(+new Date());
        },
        set: function(newValue) {
          return newValue;
        }
      },
      // size的美元价值
      sizeDollarValue(){
        if(this.orderForm.size > 0 && this.orderForm.price > 0){
          let doller = this.formatNum((this.orderForm.size * this.orderForm.price).toFixed(2));
          return ' ≈ $' + doller;
        }
        return '';
      },
      // 是否禁用下单按钮
      isDisabledOrderButton() {
        if (this.orderForm.category != 'TP' && this.orderForm.side == 'open_long') {
          return true;
        }
        return false;
      },
      changedSpotOrderFormPriceLevel2Result() {
        let percentage = ' [' + this.changedSpotOrderTrailingStopForm.priceLevel2 + '%]';
        if ('open_long' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 + Number(this.changedSpotOrderForm.priceLevel2)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else if ('open_short' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 - Number(this.changedSpotOrderForm.priceLevel2)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else {
          return '--';
        }
      },
      changedSpotOrderFormPriceLevel3Result() {
        let percentage = ' [' + this.changedSpotOrderTrailingStopForm.priceLevel3 + '%]';
        if ('open_long' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 + Number(this.changedSpotOrderForm.priceLevel3)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else if ('open_short' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 - Number(this.changedSpotOrderForm.priceLevel3)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else {
          return '--';
        }
      },
      changedSpotOrderFormTrailingStopLevel1Result() {
        let percentage = ' [' + this.changedSpotOrderTrailingStopForm.trailingStopLevel1 + '%]';
        if ('open_long' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 - Number(this.changedSpotOrderForm.trailingStopLevel1)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else if ('open_short' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 + Number(this.changedSpotOrderForm.trailingStopLevel1)))
              .toFixed(this.getDecimalPlace(this.changedSpotOrderForm.exchange, this.changedSpotOrderForm.symbol))) +
            percentage;
        } else {
          return '--';
        }
      },
      changedSpotOrderFormTrailingStopLevel2Result() {
        let percentage = ' [' + this.changedSpotOrderTrailingStopForm.trailingStopLevel2 + '%]';
        if ('open_long' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 + Number(this.changedSpotOrderForm.priceLevel2)) *
            (1 - Number(this.changedSpotOrderForm.trailingStopLevel2))).toFixed(this.getDecimalPlace(this.changedSpotOrderForm
            .exchange, this.changedSpotOrderForm.symbol))) + percentage;
        } else if ('open_short' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 - Number(this.changedSpotOrderForm.priceLevel2)) *
            (1 + Number(this.changedSpotOrderForm.trailingStopLevel2))).toFixed(this.getDecimalPlace(this.changedSpotOrderForm
            .exchange, this.changedSpotOrderForm.symbol))) + percentage;
        } else {
          return '--';
        }
      },
      changedSpotOrderFormTrailingStopLevel3Result() {
        let percentage = ' [' + this.changedSpotOrderTrailingStopForm.trailingStopLevel3 + '%]';
        if ('open_long' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 + Number(this.changedSpotOrderForm.priceLevel3)) *
            (1 - Number(this.changedSpotOrderForm.trailingStopLevel3))).toFixed(this.getDecimalPlace(this.changedSpotOrderForm
            .exchange, this.changedSpotOrderForm.symbol))) + percentage;
        } else if ('open_short' == this.changedSpotOrderForm.side) {
          return this.formatNum((this.changedSpotOrderForm.price * (1 - Number(this.changedSpotOrderForm.priceLevel3)) *
            (1 + Number(this.changedSpotOrderForm.trailingStopLevel3))).toFixed(this.getDecimalPlace(this.changedSpotOrderForm
            .exchange, this.changedSpotOrderForm.symbol))) + percentage;
        } else {
          return '--';
        }
      },
      formPrice() {
        return this.orderForm.price
      },
      // 当前交易所
      currentExchangeSpotSymbolInfos(){
        return this.spotSymbolInfos[this.exchangeCode];
      }
    },
    components: { OkexSpotMarketPrice, FtxSpotMarketPrice, AppIcon, PortfolioValue, AssetsTrendLineChart,TradingTypeChange, AssetSummary, TradingRenkoChart, TradingCandleChart, TradingHeikinAshiChart, ChangePercentage, SearchCoin },
    created() {
      this.$store.dispatch("tradingHeaderTitleHandler", 'spot');
      this.$store.dispatch("tradingTypePageHandler", this.$t("header.spot"));
      this.isLogin();
      this.judgeUserRole();
      this.orderForm.exchange = this.exchangeCode;
      this.orderForm.symbol = this.symbol;
      this.getLocalStorage();
    },
    beforeDestroy() {
      if (this.getOrdersTimers) {
        clearInterval(this.getOrdersTimers)
      }
    },
    mounted() {
      this.isExistPage();
      this.getAccounts();
      this.hasApiKey();
      this.getAllSpotOrders();
      if (this.side == "open") {
        this.plan("1");
      };
      // 获取报价货币
      let currency = localStorage.getItem('iobots-spotTradingQuoteCurrency');
      if (currency) {
        this.spotTradingQuoteCurrency = currency;
      }
      setTimeout(() => {
        if (this.strategies.length > 0) {
          this.orderForm.strategy = this.strategies[0];
        }
        // this.judgeExchangeSupportedSpotSymbols();
      }, 1000);
      // 时间
      if ('UTCTime' == this.time) {
        this.currentTimeTimer = setInterval(() => {
          this.currentTime = formatUTCTime(new Date());
          this.currentTimestamp = +new Date();
        }, 1000);
      } else {
        this.currentTimeTimer = setInterval(() => {
          this.currentTime = formatTime(new Date());
          this.currentTimestamp = +new Date();
        }, 1000);
      }
    },
    watch: {
      // 如果交易对更改 buy的时候是ask价 sell的时候是bid价
      symbol(newVal, oldVal) {
        this.orderForm.symbol = newVal;
        if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].ask !=
          null && this.marketPrice[this.symbol].bid != null) {
          "buy" == this.orderSide ? this.orderForm.price = this.marketPrice[this.symbol].ask.price : this.orderForm.price =
            this.marketPrice[this.symbol].bid.price;
        }
        if (this.takeProfitEnabled == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol))
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol));
          }
        }
        if (this.symbol) {
          this.orderSearch.symbol = this.symbol;
        }
      },
      // 监听全屏更换分页数量
      fullScreen(newVal, oldVal){
        if (newVal) {
          this.pageSize = 30;
        } else {
          this.pageSize = 10;
        }
        this.getAllSpotOrders();
      },
      openPositionsExchangeSearch(val){
        this.openPositionsAtExchanges = [];
        this.getOpenPositionsAtExchanges();
      },
      openPositionsCurrencySearch(val){
        this.openPositionsAtExchanges = [];
        this.getOpenPositionsAtExchanges();
      },
      spotTradingQuoteCurrency(newVal, oldVal) {
        if (newVal == 'USDT') {
          this.symbol = 'BTC-USDT';
          this.$router.push({
            name: "spotTrading",
            params: {
              exchangeCode: this.exchangeCode,
              symbol: "BTC-USDT"
            }
          });
        } else if (newVal == 'BTC') {
          this.symbol = 'ETH-BTC';
          this.$router.push({
            name: "spotTrading",
            params: {
              exchangeCode: this.exchangeCode,
              symbol: "ETH-BTC"
            }
          });
        }
        // 存入缓存
        localStorage.setItem('iobots-spotTradingQuoteCurrency', newVal);
      },
      // 更改订单方向 buy的时候是ask价 sell的时候是bid价
      orderSide(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.marketPrice != null && this.marketPrice[this.symbol] != null) {
            "buy" == newVal ? this.orderForm.price = this.marketPrice[this.symbol].ask.price : this.orderForm.price =
              this.marketPrice[this.symbol].bid.price;
          }
        }
        if (this.takeProfitEnabled == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol))
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol));
          }
        }
      },
      // 如果监听不为空,并且订单的价格为0 buy的时候是ask价 sell的时候是bid价
      marketPrice(v) {
        if (v != null && this.priceIsChange && this.orderForm.price == 0 && this.marketPrice != null && this.marketPrice[
            this.symbol] != null && this.marketPrice[this.symbol].bid != null && this.marketPrice[this.symbol].ask !=
          null) {
          "buy" == this.orderSide ? this.orderForm.price = this.marketPrice[this.symbol].ask.price : this.orderForm.price =
            this.marketPrice[this.symbol].bid.price;
          this.priceIsChange = false;
        }
        if (v != null && this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current != null) {
          let title = '$' + this.formatNum(this.marketPrice[this.symbol].current.price) + ' | ' + this.symbol + ' | ' + this.$t('common.documentTitle');
          this.$store.dispatch('documentTitleHandler', title);
        }
      },
      // 如果交易所代码发生变化,将表单的值清空并重新根据市场价取值
      exchangeCode(v) {
        if (v) {
          this.orderForm.exchange = v;
          this.gotoExchange(v);
          this.getAccounts();
          this.hasApiKey();
          this.orderForm.price = 0;
          this.priceIsChange = true;
        }
      },
      $route() {
        this.symbol = this.$route.params.symbol;
        this.exchangeCode = this.$route.params.exchangeCode;
      },
      takeProfitEnabled(val) {
        if (val == false) {
          this.orderForm.takeProfitPrice = 0;
          this.takeProfitString = '0.0';
        }
        if (val == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol))
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.getDecimalPlace(this.orderForm
              .exchange, this.orderForm.symbol));
          }
        }
      },
      stopLossEnabled(val) {
        if (val == false) {
          this.stopLossString = '0.0';
        }
      },
      pendingEntryOrdersExchangeSearch() {
        this.pendingEntryOrders = [],
        this.pendingEntryOrdersTotalElements = 0;
        this.pendingEntryOrdersPageNo = 1;
        this.queryPendingEntryOrdersByPages(1);
      },
      pendingEntryOrdersSymbolSearch() {
        this.pendingEntryOrders = [],
        this.pendingEntryOrdersTotalElements = 0;
        this.pendingEntryOrdersPageNo = 1;
        this.queryPendingEntryOrdersByPages(1);
      },
      pendingExitOrdersExchangeSearch() {
        this.pendingExitOrders = [],
        this.pendingExitOrdersTotalElements = 0;
        this.pendingExitOrdersPageNo = 1;
        this.queryPendingExitOrdersByPages(1);
      },
      pendingExitOrdersSymbolSearch() {
        this.pendingExitOrders = [],
        this.pendingExitOrdersTotalElements = 0;
        this.pendingExitOrdersPageNo = 1;
        this.queryPendingExitOrdersByPages(1);
      },
      submittedOrdersExchangeSearch() {
        this.submittedOrders = [],
        this.submittedOrdersTotalElements = 0;
        this.submittedOrdersPageNo = 1;
        this.querySubmittedOrdersByPages(1);
      },
      submittedOrdersSymbolSearch() {
        this.submittedOrders = [],
        this.submittedOrdersTotalElements = 0;
        this.submittedOrdersPageNo = 1;
        this.querySubmittedOrdersByPages(1);
      },
      unfilledExchangeOrdersExchangeSearch() {
        this.unfilledExchangeOrders = [];
        this.unfilledExchangeOrdersTotalElements = 0;
        this.unfilledExchangeOrdersPageNo = 1;
        this.queryUnfilledExchangeOrdersByPages(1);
      },
      unfilledExchangeOrdersSymbolSearch() {
        this.unfilledExchangeOrders = [];
        this.unfilledExchangeOrdersTotalElements = 0;
        this.unfilledExchangeOrdersPageNo = 1;
        this.queryUnfilledExchangeOrdersByPages(1);
      },
      filledExchangeOrdersExchangeSearch() {
        this.filledExchangeOrders = [];
        this.filledExchangeOrdersTotalElements = 0;
        this.filledExchangeOrdersPageNo = 1;
        this.queryFilledExchangeOrdersByPages(1);
      },
      filledExchangeOrdersSymbolSearch() {
        this.filledExchangeOrders = [];
        this.filledExchangeOrdersTotalElements = 0;
        this.filledExchangeOrdersPageNo = 1;
        this.queryFilledExchangeOrdersByPages(1);
      },
      openPositionsOrdersExchangeSearch(val) {
        this.openPositionsOrders = [];
        this.openPositionsOrdersTotalElements = 0;
        this.openPositionsOrdersPageNo = 1;
        this.queryOpenPositionsOrdersByPages(1);
      },
      openPositionsOrdersSymbolSearch(val) {
        this.openPositionsOrders = [];
        this.openPositionsOrdersTotalElements = 0;
        this.openPositionsOrdersPageNo = 1;
        this.queryOpenPositionsOrdersByPages(1);
      },
      orderForm: {
        handler(newObject, oldObject) {
          this.orderForm.price = Number(this.orderForm.price);
          this.orderForm.size = Number(this.orderForm.size);
          // this.orderForm.takeProfitPrice = Number(this.orderForm.takeProfitPrice);
          if (this.takeProfitEnabled == true && this.orderForm.takeProfitPrice == 0 && this.formProfitPriceCount == 0) {
            this.formProfitPriceCount++;
            if ("open_long" == this.orderForm.side) {
              this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.getDecimalPlace(this.orderForm.exchange, this.orderForm.symbol))
            }
            if ("open_short" == this.orderForm.side) {
              this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.getDecimalPlace(this.orderForm.exchange, this.orderForm.symbol));
            }
          }
        },
        immediate: true,
        deep: true
      },
      // 监听订单搜索
      orderSearch: {
        handler(newObject, oldObject) {
          this.pendingEntryOrdersPageNo = 1;
          this.pendingExitOrdersPageNo = 1;
          this.submittedOrdersPageNo = 1;
          this.unfilledExchangeOrdersPageNo = 1;
          this.filledExchangeOrdersPageNo = 1;
          this.openPositionsOrdersPageNo = 1;
          this.getAllSpotOrders();
          // 切换为该交易对
          this.gotoSpotOrder(this.orderSearch.symbol);
        },
        immediate: true,
        deep: true
      },
      // 监听websocket有推送
      webSocketDataIndex(newVal, oldVal){
        if (this.webSocketData) {
          this.getWebSocketData();
        }
      },
      formPrice(newVal, oldVal) {
        if (this.takeProfitEnabled == true && newVal != oldVal) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.getDecimalPlace(this.orderForm.exchange, this.orderForm.symbol))
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.getDecimalPlace(this.orderForm.exchange, this.orderForm.symbol));
          }
        }
      },
      trailingStopLevel1(newVal, oldValue) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel1 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel1 = Number(newVal);
        }
        this.orderForm.trailingStopLevel1 = newVal / 100;
        this.$forceUpdate();
      },
      trailingStopLevel2(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel2 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel2 = Number(newVal);
        }
        this.orderForm.trailingStopLevel2 = newVal / 100;
        this.$forceUpdate();
      },
      trailingStopLevel3(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel3 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel3 = Number(newVal);
        }
        this.orderForm.trailingStopLevel3 = newVal / 100;
        this.$forceUpdate();
      },
      priceLevel2(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.priceLevel2 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.priceLevel2 = Number(newVal);
        }
        this.orderForm.priceLevel2 = newVal / 100;
        this.$forceUpdate();
      },
      priceLevel3(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.priceLevel3 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.priceLevel3 = Number(newVal);
        }
        this.orderForm.priceLevel3 = newVal / 100;
        this.$forceUpdate();
      },
      // changedSpotOrderForm: {
      //   handler(newObject, oldObject) {
      //     this.changedSpotOrderTrailingStopForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) * 100).toFixed(2);
      //     this.changedSpotOrderTrailingStopForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) * 100).toFixed(2);
      //     this.changedSpotOrderTrailingStopForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) * 100).toFixed(2);
      //     this.changedSpotOrderTrailingStopForm.priceLevel2 = Number(Number(newObject.priceLevel2) * 100).toFixed(2);
      //     this.changedSpotOrderTrailingStopForm.priceLevel3 = Number(Number(newObject.priceLevel3) * 100).toFixed(2);
      //   },
      //   immediate: true,
      //   deep: true
      // },
      // changedSpotOrderTrailingStopForm: {
      //     handler(newObject, oldObject) {
      //       this.changedSpotOrderForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) / 100).toFixed(4);
      //       this.changedSpotOrderForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) / 100).toFixed(4);
      //       this.changedSpotOrderForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) / 100).toFixed(4);
      //       this.changedFSpotOrderForm.priceLevel2 = Number(Number(newObject.priceLevel2) / 100).toFixed(4);
      //       this.changedSpotOrderForm.priceLevel3 = Number(Number(newObject.priceLevel3) / 100).toFixed(4);
      //     },
      //     immediate: true,
      //     deep: true
      // },
      // 监听止损比例方案
      planVal(newVal, oldVal) {
        if (newVal != oldVal) {
          this.plan(newVal)
        }
      },
      isEditingSpotOrder(val) {
        this.changedSpotOrderForm.trailingStopLevel1 = Number(Number(Number(this.changedSpotOrderTrailingStopForm
          .trailingStopLevel1).toFixed(4)) / 100).toFixed(4);
        this.changedSpotOrderForm.trailingStopLevel2 = Number(Number(Number(this.changedSpotOrderTrailingStopForm
          .trailingStopLevel2).toFixed(4)) / 100).toFixed(4);
        this.changedSpotOrderForm.trailingStopLevel3 = Number(Number(Number(this.changedSpotOrderTrailingStopForm
          .trailingStopLevel3).toFixed(4)) / 100).toFixed(4);
        this.changedSpotOrderForm.priceLevel2 = Number(Number(Number(this.changedSpotOrderTrailingStopForm.priceLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.changedSpotOrderForm.priceLevel3 = Number(Number(Number(this.changedSpotOrderTrailingStopForm.priceLevel3)
          .toFixed(4)) / 100).toFixed(4);

        this.changedSpotOrderTrailingStopForm.trailingStopLevel1 = (Number(this.changedSpotOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.trailingStopLevel2 = (Number(this.changedSpotOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.trailingStopLevel3 = (Number(this.changedSpotOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.priceLevel2 = (Number(this.changedSpotOrderForm.priceLevel2) * 100)
          .toFixed(2);
        this.changedSpotOrderTrailingStopForm.priceLevel3 = (Number(this.changedSpotOrderForm.priceLevel3) * 100)
          .toFixed(2);
        //  changedSpotOrderForm: {
        //   handler(newObject, oldObject) {
        //     this.changedSpotOrderTrailingStopForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) * 100).toFixed(2);
        //     this.changedSpotOrderTrailingStopForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) * 100).toFixed(2);
        //     this.changedSpotOrderTrailingStopForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) * 100).toFixed(2);
        //     this.changedSpotOrderTrailingStopForm.priceLevel2 = Number(Number(newObject.priceLevel2) * 100).toFixed(2);
        //     this.changedSpotOrderTrailingStopForm.priceLevel3 = Number(Number(newObject.priceLevel3) * 100).toFixed(2);
        //   },
        //   immediate: true,
        //   deep: true
        // },
        // changedSpotOrderTrailingStopForm: {
        //     handler(newObject, oldObject) {
        //       this.changedSpotOrderForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) / 100).toFixed(4);
        //       this.changedSpotOrderForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) / 100).toFixed(4);
        //       this.changedSpotOrderForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) / 100).toFixed(4);
        //       this.changedSpotOrderForm.priceLevel2 = Number(Number(newObject.priceLevel2) / 100).toFixed(4);
        //       this.changedSpotOrderForm.priceLevel3 = Number(Number(newObject.priceLevel3) / 100).toFixed(4);
        //     },
        //     immediate: true,
        //     deep: true
        // },
      },
      networkStatus(newVal, oldVal){
        // 网络重新连接时，刷新订单和账户
        if (newVal) {
          this.pageRefreshDateTime = formatISO8601Time(+new Date());
          this.getAccounts();
          this.getPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo, false);
          this.getPendingExitOrdersByPages(this.pendingExitOrdersPageNo, false);
          this.getSubmittedOrdersByPages(this.submittedOrdersPageNo, false);
          this.getUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo, false);
          this.getFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo, false);
          this.getOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo, false);
          this.getOpenPositionsAtExchanges();
        }
      },
      // 监听仓位隐藏变动时保存在缓存
      hideNonePositions(newVal, oldVal){
        localStorage.setItem('iobots-hideSpotNonePositions', newVal ? 1 : 0);
      },
      // 监听子组件变动时保存在缓存
      childComponent(newVal, oldVal){
        localStorage.setItem('iobots-spotChildComponent', newVal);
      }
    },
    methods: {
      hint(message) {
        this.$store.dispatch('snackbarMessageHandler', message);
      },
      // 是否存在该链接
      isExistPage() {
        let existExchangeCode = isExistExchangeCode(this.exchanges, this.exchangeCode);
        if (!existExchangeCode) {
          this.$router.push({
            name: 'page404'
          })
        }
        // let existSymbol = isExistSymbol(this.spotSymbols, this.symbol);
        // if (!existSymbol) {
        //   this.$router.push({
        //     name: 'page404'
        //   })
        // }
      },
      // 是否登录
      isLogin() {
        if (!this.user.token) {
          this.$router.push({
            name: 'home'
          });
        }
      },
      // 判断用户角色
      judgeUserRole() {
        if (this.user != null && this.user.role != 'TRADER') {
          this.$store.dispatch('snackbarMessageHandler', this.$t("common.notTrader"));
          this.$router.push({
            name: 'home'
          })
        }
      },
      // 判断交易所支持的交易对
      // judgeExchangeSupportedSpotSymbols() {
      //   if (this.exchangeSupportedSpotSymbols != [] && this.exchangeSupportedSpotSymbols[this.exchangeCode].length > 0) {
      //     let symbols = this.exchangeSupportedSpotSymbols[this.exchangeCode];
      //     for (let i = 0, len = symbols.length; i < len; i++) {
      //       if (symbols[i].symbol == this.symbol && symbols[i].supported == false) {
      //         this.symbol = 'BTC-USDT';
      //         this.$router.push({
      //           name: "spotTrading",
      //           params: {
      //             exchangeCode: this.exchangeCode,
      //             symbol: this.symbol
      //           }
      //         });
      //       }
      //     }

      //   }
      // },
      // 刷新所有订单
      refreshAllSpotOrders() {
        if (!this.fullScreen) {
          // 不是全屏时候刷新摘要
          this.$refs.summary.refresh();
        }
        this.pendingEntryOrders = [],
        this.pendingExitOrders = [],
        this.submittedOrders = [],
        this.unfilledExchangeOrders = [],
        this.filledExchangeOrders = [],
        this.openPositionsOrders = [],
        this.getAllSpotOrders();
        this.isRefresh = true;
      },
      unfresh() {
        this.isRefresh = false;
      },
      // 查询所有订单
      getAllSpotOrders() {
        this.pageRefreshDateTime = formatISO8601Time(+new Date());
        this.getAccounts();
        this.queryPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo);
        this.queryPendingExitOrdersByPages(this.pendingExitOrdersPageNo);
        this.querySubmittedOrdersByPages(this.submittedOrdersPageNo);
        this.queryUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo);
        this.queryFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo);
        this.queryOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo);
        this.getOpenPositionsAtExchanges();
        this.pendingEntryOrdersTotalElements = 0;
        this.pendingExitOrdersTotalElements = 0;
        this.submittedOrdersTotalElements = 0;
        this.unfilledExchangeOrdersTotalElements = 0;
        this.filledExchangeOrdersTotalElements = 0;
        this.openPositionsOrdersTotalElements = 0;
      },
      gotoSpotOrder(symbol) {
        if (symbol) {
          this.orderForm.symbol = symbol;
          this.$router.push({
            name: 'spotTrading',
            params: {
              exchangeCode: this.orderForm.exchange,
              symbol: symbol
            }
          });
        }
      },
      // 选择交易所
      changeExchange(exchange) {
        this.exchangeCode = exchange;
      },
      // 跳转到指定的交易所链接
      gotoExchange(exchangeCode) {
        // this.judgeExchangeSupportedSpotSymbols();
        this.$router.push({
          name: "spotTrading",
          params: {
            exchangeCode: exchangeCode,
            symbol: this.symbol
          }
        });
      },
      // 跳转到信号页面
      gotoMoreSignals() {
        // this.$router.push({
        //   name: 'moreSignals'
        // });
        this.$router.push({ name: 'home' });
      },
      // NextOrder3
      gotoNextOrder3() {
        this.nextOrder = "next3";
        this.cancelDialog();
      },
      // 改变方向
      changeSide(side) {
        // open/close
        this.side = side;
        this.buttonSide = "open" == side ? ("buy" == this.orderSide ? "long" : "short") : this.orderSide;
        this.orderForm.side = this.side + '_' + this.buttonSide;
        if (side == "close") {
          this.planVal = "0";
        } else {
          this.planVal = "1";
        }
      },
      // 改变订单方向
      changeOrderSide(orderSide) {
        // buy/sell
        this.orderSide = orderSide;
        this.buttonSide = "open" == this.side ? ("buy" == orderSide ? "long" : "short") : this.orderSide;
        this.orderForm.side = this.side + '_' + this.buttonSide;
      },
      // 提交订单
      submitOrder() {
        // 发送下单请求
        this.submittedOrderFormLoading = true;
        let api = config.baseUrl + '/order/spot/private/order';
        this.orderForm.sendOrderStartTimestamp = parseISO8601ToTimestamp(this.orderForm.sendOrderStartDatetime);
        this.$http.post(api, this.orderForm).then(response => {
          let data = response.data;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          this.cancelLoading();
          if (200 == data.code) {
            this.orderForm.exchange = this.exchangeCode;
            this.orderForm.orderType = "limit";
            this.orderForm.symbol = this.symbol;
            this.orderForm.price = 0;
            this.orderForm.size = 0;
            this.pendingEntryOrdersPageNo = 1;
            this.takeProfitEnabled = false;
            this.orderForm.takeProfitPrice = 0;
            this.cancelLoading();
            this.nextOrder = "next1";
            this.submittedOrderFormLoading = false;
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          this.submittedOrderFormLoading = false;
        });
      },
      //分页查询放置的进入订单
      getPendingEntryOrdersByPages(pendingEntryOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.pendingEntryLoading = true;
          this.pendingEntryOrders = [];
        }
        let api = config.baseUrl + '/order/spot/private/orders/pendingEntry?pageNo=' + (pendingEntryOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.pendingEntryOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所code转为名字
            for (let i = 0, iLength = this.pendingEntryOrders.length; i < iLength; i++) {
              this.pendingEntryOrders[i].exchangeName = this.getExchangeNameByCode(this.pendingEntryOrders[i].exchange);
              switch (this.pendingEntryOrders[i].category) {
                case 'IOB':
                  this.pendingEntryOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.pendingEntryOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.pendingEntryOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.pendingEntryOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.pendingEntryOrders[i].bgColor = '';
                  break;
              }
            }
            this.pendingEntryOrdersTotalElements = data.result.totalElements;
            this.pendingEntryOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.pendingEntryLoading = false;
          } else {
            this.pendingEntryOrders = [];
            this.pendingEntryOrdersTotalElements = 0;
            this.pendingEntryLoading = false;
          }
        });
      },
      //分页查询放置的退出订单
      getPendingExitOrdersByPages(pendingExitOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.pendingExitLoading = true;
          this.pendingExitOrders = [];
        }
        let api = config.baseUrl + '/order/spot/private/orders/pendingExit?pageNo=' + (pendingExitOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.pendingExitOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所code转为名字
            for (let i = 0, iLength = this.pendingExitOrders.length; i < iLength; i++) {
              this.pendingExitOrders[i].exchangeName = this.getExchangeNameByCode(this.pendingExitOrders[i].exchange);
              switch (this.pendingExitOrders[i].category) {
                case 'IOB':
                  this.pendingExitOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.pendingExitOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.pendingExitOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.pendingExitOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.pendingExitOrders[i].bgColor = '';
                  break;
              }
            }
            this.pendingExitOrdersTotalElements = data.result.totalElements;
            this.pendingExitOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.pendingExitLoading = false;
          } else {
            this.pendingExitOrders = [];
            this.pendingExitOrdersTotalElements = 0;
            this.pendingExitLoading = false;
          }
        });
      },
      //分页查询已提交至交易所的订单
      getSubmittedOrdersByPages(submittedOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.submittedExchangeOrdersLoading = true;
          this.submittedOrders = [];
        }
        let api = config.baseUrl + '/order/spot/private/orders/submitted?pageNo=' + (submittedOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.submittedOrders = data.result.orderList;
            this.submittedExchangeOrdersLoading = false;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.submittedOrders.length; i < iLength; i++) {
              this.submittedOrders[i].exchangeName = this.getExchangeNameByCode(this.submittedOrders[i].exchange);
              switch (this.submittedOrders[i].category) {
                case 'IOB':
                  this.submittedOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.submittedOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.submittedOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.submittedOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.submittedOrders[i].bgColor = '';
                  break;
              }
            }
            this.submittedOrdersTotalElements = data.result.totalElements;
            this.submittedOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
          } else {
            this.submittedOrders = [];
            this.submittedOrdersTotalElements = 0;
            this.submittedExchangeOrdersLoading = false;
          }
        });
      },
      //分页查询在交易所未成交的订单
      getUnfilledExchangeOrdersByPages(unfilledExchangeOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.unfilledAtExchangesLoading = true;
          this.unfilledExchangeOrders = [];
        }
        let api = config.baseUrl + '/order/spot/private/orders/unfilled?pageNo=' + (unfilledExchangeOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.unfilledExchangeOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所code转为名字
            for (let i = 0, iLength = this.unfilledExchangeOrders.length; i < iLength; i++) {
              this.unfilledExchangeOrders[i].exchangeName = this.getExchangeNameByCode(this.unfilledExchangeOrders[
                i].exchange);
              switch (this.unfilledExchangeOrders[i].category) {
                case 'IOB':
                  this.unfilledExchangeOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.unfilledExchangeOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.unfilledExchangeOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.unfilledExchangeOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.unfilledExchangeOrders[i].bgColor = '';
                  break;
              }
            }
            this.unfilledExchangeOrdersTotalElements = data.result.totalElements;
            this.unfilledExchangeOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.unfilledAtExchangesLoading = false;
          } else {
            this.unfilledExchangeOrders = [];
            this.unfilledExchangeOrdersTotalElements = 0;
            this.unfilledAtExchangesLoading = false;
          }
        });
      },
      //分页查询在交易所已成交的订单
      getFilledExchangeOrdersByPages(filledExchangeOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.filledExchangeOrdersLoading = true;
          this.filledExchangeOrders = [];
        }
        this.filledExchangeOrdersTotalElements = 0;
        let api = config.baseUrl + '/order/spot/private/orders/filled?pageNo=' + (filledExchangeOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.filledExchangeOrders = data.result.orderList;
            this.filledExchangeOrdersLoading = false;
            let notes = data.result.orderNotes;
            // 将交易所code转为名字
            for (let i = 0, iLength = this.filledExchangeOrders.length; i < iLength; i++) {
              this.filledExchangeOrders[i].exchangeName = this.getExchangeNameByCode(this.filledExchangeOrders[i].exchange);
              switch (this.filledExchangeOrders[i].category) {
                case 'IOB':
                  this.filledExchangeOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.filledExchangeOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.filledExchangeOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.filledExchangeOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.filledExchangeOrders[i].bgColor = '';
                  break;
              }
            }
            this.filledExchangeOrdersTotalElements = data.result.totalElements;
            this.filledExchangeOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
          } else {
            this.filledExchangeOrders = [];
            this.filledExchangeOrdersTotalElements = 0;
            this.filledExchangeOrdersLoading = false;
          }
        });
      },
      //分页查询在交易所持仓的订单
      getOpenPositionsOrdersByPages(openPositionsOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.openPositionsOrders = [];
          this.openPositionsLoading = true;
        }
        let api = config.baseUrl + '/order/spot/private/orders/openPositions?pageNo=' + (openPositionsOrdersPageNo - 1) + "&pageSize=" + this.pageSize;
        if(this.orderSearch.exchange){
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if(this.orderSearch.symbol){
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.openPositionsOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所code转为名字
            for (let i = 0, iLength = this.openPositionsOrders.length; i < iLength; i++) {
              this.openPositionsOrders[i].exchangeName = this.getExchangeNameByCode(this.openPositionsOrders[i].exchange);
              switch (this.openPositionsOrders[i].category) {
                case 'IOB':
                  this.openPositionsOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.openPositionsOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.openPositionsOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.openPositionsOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.openPositionsOrders[i].bgColor = '';
                  break;
              }
            }
            this.openPositionsOrdersTotalElements = data.result.totalElements;
            this.openPositionsOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.openPositionsLoading = false;
          } else {
            this.openPositionsOrders = [];
            this.openPositionsOrdersTotalElements = 0;
            this.openPositionsLoading = false;
          }
        });
      },
      // 查询持仓信息
      getOpenPositionsAtExchanges(){
        this.openPositionsAtExchangesLoading = true;
        let api = config.baseUrl + '/exchange/spot/private/getOpenPositions?1=1';
        if(this.openPositionsExchangeSearch){
          api = api + '&exchange=' + this.openPositionsExchangeSearch;
        }
        if(this.openPositionsCurrencySearch){
          api = api + '&currency=' + this.openPositionsCurrencySearch;
        }
        this.$http.get(api).then(response => {
            let data = response.data;
            this.openPositionsAtExchangesLoading = false;
            if(data.code==200){
              this.openPositionsAtExchanges = data.result;
              this.openPositionsAtExchangesTotalElements = data.result.length;
              this.openPositionsAtExchangesTotalPage = Math.ceil(this.openPositionsAtExchangesTotalElements/this.pageSize);
            } else {
              this.openPositionsAtExchanges = [];
              this.openPositionsAtExchangesTotalElements = 0;
              this.openPositionsAtExchangesTotalPage = 0;
            }
          }, error => {
            this.openPositionsAtExchanges = [];
            this.openPositionsAtExchangesLoading = false;
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          }
        )
      },
      // 根据订单Id删除未提交的订单
      deleteUnsubmittedOrderById(id) {
        this.unsubmittedOrdersId = id;
        this.deleteUnsubmittedOrderDialog = true;
      },
      // 根据订单ID删除已提交的订单
      deleteSubmittedOrderById(id) {
        this.submittedOrdersId = id;
        this.cancelSubmittedOrderDialog = true;
      },
      // 删除未提交的订单
      deleteUnsubmittedOrder() {
        let id = this.unsubmittedOrdersId;
        this.deleteUnsubmittedLoading = true;
        let api = config.baseUrl + '/order/spot/private/deleteUnsubmitted?id=' + id;
        this.$http.delete(api).then(response => {
          let data = response.data;
          this.cancelLoading();
          if (data.code == 200) {
            // 删除成功
            this.deleteUnsubmittedOrderDialog = false;
            this.pendingEntryOrdersPageNo = 1;
            this.pendingExitOrdersPageNo = 1;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 取消已提交的订单
      cancelSubmittedOrder() {
        let id = this.submittedOrdersId;
        this.cancelSubmittedLoading = true;
        let api = config.baseUrl + '/order/spot/private/cancelSubmitted?id=' + id;
        this.$http.delete(api).then(response => {
          let data = response.data;
          this.cancelLoading();
          if (data.code == 200) {
            // 取消成功
            this.submittedOrdersPageNo = 1;
            this.unfilledExchangeOrdersPageNo = 1;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      //关闭加载中
      cancelLoading() {
        this.submitBtnLoading = false;
        this.orderConfirmationDialog = false;
        this.deleteUnsubmittedLoading = false;
        this.cancelSubmittedOrderDialog = false;
        this.cancelSubmittedLoading = false;
        this.submittedOrderFormLoading = false;
      },
      //关闭弹窗
      cancelDialog() {
        this.dialog = false;
        this.cancelLoading();
      },
      // 是否存在订单
      isExistOrder() {
        this.submittedOrderFormLoading = true;
        if (this.$refs.orderForm.validate()) {
          let api = config.baseUrl + '/order/spot/private/isExistOrder?symbol=' + this.orderForm.symbol;
          this.$http.get(api).then(response => {
            let data = response.data;
            // this.cancelLoading();
            if (data.code == 200) {
              if (data.result == true) {
                // 存在订单，显示提示消息
                this.submittedOrderFormLoading = false;
                // this.cancelDialog();
                this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.isExistOrderMsgHandler")));
              } else {
                // 不存在订单，新增订单
                this.submitOrder();
              }
            }
          }, error => {
            this.cancelLoading();
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          });
        } else {
          this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidParameterMsgHandler")));
        }
      },
      // 判断参数是否正确
      judgeParameters(isShowLargePriceGap) {
        this.largePriceGapDialog = false;
        if (!this.isSetApiKey) {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.noSetApiKey'));
          return;
        }
        if (this.$refs.orderForm.validate()) {
          if (this.orderForm.strategy == null || this.orderForm.strategy == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidStrategyMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.category == null || this.orderForm.category == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidCategoryMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.price <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidPriceMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.size <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidVolume")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.takeProfitPrice < 0) {
            this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: Take Profit Price');
            this.cancelLoading();
            return;
          }
          // 是否显示大的价格间隙，不显示则直接跳过
          if (isShowLargePriceGap) {
            let currentPrice = 0;
            if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current !=
              null) {
              currentPrice = Number(this.marketPrice[this.symbol].current.price);
            }
            if (currentPrice > 0 && (this.orderForm.price < currentPrice * 0.75 || this.orderForm.price > currentPrice *
                1.25)) {
              this.largePriceGapDialog = true;
              return;
            }
          }
          let ts = +new Date();
          this.orderForm.sendOrderStartDatetime = formatISO8601Time(ts);
          this.orderForm.sendOrderStartTimestamp = ts;
          if ('open' == this.side) {
            // 显示设置移动止损的弹框
            this.trailingStopDialog = true;
          } else {
            // 显示详情
            this.orderConfirmationDialog = true;
          }
        }
      },
      // 手机判断参数是否正确
      mobileJudgeParameters(isShowLargePriceGap) {
        this.largePriceGapDialog = false;
        if (!this.isSetApiKey) {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.noSetApiKey'));
          return;
        }
        if (this.$refs.mobileOrderForm.validate()) {
          if (this.orderForm.strategy == null || this.orderForm.strategy == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidStrategyMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.category == null || this.orderForm.category == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidCategoryMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.price <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidPriceMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.size <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidVolume")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.takeProfitPrice < 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("spotTrading.invalidTakeProfitPrice")));
            this.cancelLoading();
            return;
          }
          // 是否显示大的价格间隙，不显示则直接跳过
          if (isShowLargePriceGap) {
            let currentPrice = 0;
            if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current != null) {
              currentPrice = Number(this.marketPrice[this.symbol].current.price);
            }
            if (currentPrice > 0 && (this.orderForm.price < currentPrice * 0.75 || this.orderForm.price > currentPrice * 1.25)) {
              this.largePriceGapDialog = true;
              return;
            }
          }
          let ts = +new Date();
          this.orderForm.sendOrderStartDatetime = formatISO8601Time(ts);
          this.orderForm.sendOrderStartTimestamp = ts;
          if ('open' == this.side) {
            // 显示设置移动止损的弹框
            this.nextOrder = "next3";
          } else {
            // 显示详情
            this.orderConfirmationDialog = true;
          }
        }
      },
      // 显示设置移动止损的弹框
      showTrailingStopDialog() {
        this.orderConfirmationDialog = false;
        this.submitBtnLoading = false;
        this.trailingStopDialog = true;
      },
      // 设置移动止损等级
      setTrailingStop() {
        if (this.orderForm.category == 'MR') {
          this.plan('MR');
        }
        this.trailingStopDialog = false;
        this.orderConfirmationDialog = true;
        this.submitBtnLoading = true;
      },
      // 查询是否存在ApiKey
      hasApiKey() {
        let api = config.baseUrl + "/exchange/spot/private/hasApiKey?exchange=" + this.exchangeCode;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.isSetApiKey = true;
          } else {
            this.isSetApiKey = false;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 获取账户余额
      getAccounts() {
        let api = config.baseUrl + "/exchange/" + this.exchangeCode + "/spot/private/accounts";
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.accounts = data.result;
          } else if (data.code != 404) {
            this.accounts = null;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.accounts = null;
          }
        }, error => {
          this.accounts = null;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 格式化时间
      formatDate(timestamp) {
        if ('UTCTime' == this.time) {
          return formatUTCTime(timestamp);
        } else {
          return formatTime(timestamp);
        }
      },
      // 获取小数位
      getDecimalPlace(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return 8;
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            return currentExchangeSpotSymbolInfos[i].priceDecimalPlace;
          }
        }
        return 8;
      },
      // 交易所id转为交易所name
      getExchangeNameByCode(code) {
        return getExchangeNameByCode(this.exchanges, code);
      },
      // 格式化数字
      formatNum(num) {
        return formatNumber(num);
      },
      // 显示修改订单的弹框
      showChangeOrderDialog(spotOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showChangedSpotOrder = JSON.parse(JSON.stringify(spotOrder));
        this.changedSpotOrderForm = JSON.parse(JSON.stringify(spotOrder));
        this.changedSpotOrderTrailingStopForm.trailingStopLevel1 = Number(Number(this.changedSpotOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.trailingStopLevel2 = Number(Number(this.changedSpotOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.trailingStopLevel3 = Number(Number(this.changedSpotOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.priceLevel2 = Number(Number(this.changedSpotOrderForm.priceLevel2) *
          100).toFixed(2);
        this.changedSpotOrderTrailingStopForm.priceLevel3 = Number(Number(this.changedSpotOrderForm.priceLevel3) *
          100).toFixed(2);
        this.changeOrderDialog = true;
        // 调用common.js的函数
        this.changedSpotOrderForm.exchange = spotOrder.exchange;
      },
      // 显示修改订单定时器的弹框
      showChangeTimeDialog(spotOrder) {
        if (spotOrder == null) {
          this.$store.dispatch('snackbarMessageHandler', 'The order was not found.');
        }
        this.changeTimeOrder.id = spotOrder.id;
        this.changeTimeOrder.side = spotOrder.side;
        this.changeTimeOrder.status = spotOrder.status;
        this.changeTimeOrder.spotOrderId = spotOrder.spotOrderId;
        this.changeTimeOrder.beFixedStopLossPrice = spotOrder.beFixedStopLossPrice;
        this.changeTimeOrder.sendOrderEndSwitch = (spotOrder.sendOrderEndTimestamp == -1);
        this.changeTimeOrder.takeProfitEndSwitch = (spotOrder.takeProfitEndTimestamp == -1);
        this.changeTimeOrder.stopLoss1EndSwitch = (spotOrder.stopLoss1EndTimestamp == -1);
        this.changeTimeOrder.stopLossEndSwitch = (spotOrder.stopLossEndTimestamp == -1);
        this.changeTimeOrder.fixedStopLossEndSwitch = (spotOrder.fixedStopLossEndTimestamp == -1);
        this.changeTimeOrder.timestamp = formatISO8601Time(spotOrder.timestamp);
        this.changeTimeOrder.sendOrderStartTimestamp = formatISO8601Time(spotOrder.sendOrderStartTimestamp);
        this.changeTimeOrder.takeProfitStartTimestamp = formatISO8601Time(spotOrder.takeProfitStartTimestamp);
        this.changeTimeOrder.stopLoss1StartTimestamp = formatISO8601Time(spotOrder.stopLoss1StartTimestamp);
        this.changeTimeOrder.stopLossStartTimestamp = formatISO8601Time(spotOrder.stopLossStartTimestamp);
        this.changeTimeOrder.fixedStopLossStartTimestamp = formatISO8601Time(spotOrder.fixedStopLossStartTimestamp);
        if (spotOrder.sendOrderEndTimestamp == -1) {
          this.changeTimeOrder.sendOrderEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.sendOrderEndTimestamp = formatISO8601Time(spotOrder.sendOrderEndTimestamp);
        }
        if (spotOrder.takeProfitEndTimestamp == -1) {
          this.changeTimeOrder.takeProfitEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.takeProfitEndTimestamp = formatISO8601Time(spotOrder.takeProfitEndTimestamp);
        }
        if (spotOrder.stopLoss1EndTimestamp == -1) {
          this.changeTimeOrder.stopLoss1EndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.stopLoss1EndTimestamp = formatISO8601Time(spotOrder.stopLoss1EndTimestamp);
        }
        if (spotOrder.stopLossEndTimestamp == -1) {
          this.changeTimeOrder.stopLossEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.stopLossEndTimestamp = formatISO8601Time(spotOrder.stopLossEndTimestamp);
        }
        if (spotOrder.fixedStopLossEndTimestamp == -1) {
          this.changeTimeOrder.fixedStopLossEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.fixedStopLossEndTimestamp = formatISO8601Time(spotOrder.fixedStopLossEndTimestamp);
        }
        this.changeTimeDialog = true;
      },
      // 提交修改时间订单的请求
      submitChangeTimeOrder() {
        this.changeTimeLoading = true;
        let changeTimeOrderParam = {
          id: this.changeTimeOrder.id,
          spotOrderId: this.changeTimeOrder.spotOrderId,
          sendOrderStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.sendOrderStartTimestamp),
          sendOrderEndTimestamp: this.changeTimeOrder.sendOrderEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .sendOrderEndTimestamp),
          takeProfitStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.takeProfitStartTimestamp),
          takeProfitEndTimestamp: this.changeTimeOrder.takeProfitEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .takeProfitEndTimestamp),
          stopLoss1StartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.stopLoss1StartTimestamp),
          stopLoss1EndTimestamp: this.changeTimeOrder.stopLoss1EndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .stopLoss1EndTimestamp),
          stopLossStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.stopLossStartTimestamp),
          stopLossEndTimestamp: this.changeTimeOrder.stopLossEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .stopLossEndTimestamp),
          fixedStopLossStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.fixedStopLossStartTimestamp),
          fixedStopLossEndTimestamp: this.changeTimeOrder.fixedStopLossEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .fixedStopLossEndTimestamp),
        };
        let api = config.baseUrl + '/order/spot/private/edit/time';
        this.$http.post(api, changeTimeOrderParam).then(response => {
          let data = response.data;
          this.changeTimeLoading = false;
          this.changeTimeDialog = false;
          if (data.code == 200) {
            this.changeOrderDialog = false;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.changeOrderDialog = false;
          }
        }, error => {
          this.changeTimeLoading = false;
          this.changeTimeDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 提交改变的现货订单请求
      submitChangedSpotOrder() {
        this.changeOrderLoading = true;
        let spotOrderParam = {
          id: this.changedSpotOrderForm.id,
          price: Number(this.changedSpotOrderForm.price),
          size: Number(this.changedSpotOrderForm.size),
          takeProfitPrice: Number(this.changedSpotOrderForm.takeProfitPrice),
          beFixedStopLossPrice: this.changedSpotOrderForm.beFixedStopLossPrice,
          fixedStopLossPrice: Number(this.changedSpotOrderForm.fixedStopLossPrice),
          trailingStopLevel1: Number(this.changedSpotOrderForm.trailingStopLevel1),
          trailingStopLevel2: Number(this.changedSpotOrderForm.trailingStopLevel2),
          trailingStopLevel3: Number(this.changedSpotOrderForm.trailingStopLevel3),
          priceLevel2: Number(this.changedSpotOrderForm.priceLevel2),
          priceLevel3: Number(this.changedSpotOrderForm.priceLevel3)
        };
        let api = config.baseUrl + '/order/spot/private/edit/order';
        this.$http.post(api, spotOrderParam).then(response => {
          let data = response.data;
          this.changeOrderLoading = false;
          this.isEditingSpotOrder = true;
          if (data.code == 200) {
            this.changeOrderDialog = false;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.changeOrderDialog = false;
          }
        }, error => {
          this.changeOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          this.changeOrderDialog = false;
        });
      },
      // 显示退出仓位订单的弹框
      showExitPositionOrderDialog(spotOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showExitPositionOrder = JSON.parse(JSON.stringify(spotOrder));
        this.exitPositionOrderForm.price = 0;
        this.isEditingExitPositionOrder = true;
        this.exitPositionOrderDialog = true;
      },
      // 获得退出仓位订单时的对手价
      getExitPositionBestBidOffer() {
        if (this.marketPrice != null && this.marketPrice[this.showExitPositionOrder.symbol] != null) {
          if ('open_long' == this.showExitPositionOrder.side) {
            this.exitPositionOrderForm.price = Number(this.marketPrice[this.showExitPositionOrder.symbol].bid.price);
          } else if ('open_short' == this.showExitPositionOrder.side) {
            this.exitPositionOrderForm.price = Number(this.marketPrice[this.showExitPositionOrder.symbol].ask.price);
          } else {
            this.$store.dispatch('snackbarMessageHandler', 'Invalid Side');
            this.isEditingExitPositionOrder = true;
          }
        }
      },
      // 发送退出仓位的订单
      submitAddExitSpotOrder() {
        if (this.exitPositionOrderForm.price <= 0) {
          this.$store.dispatch('snackbarMessageHandler', 'Invalid Price');
          this.isEditingExitPositionOrder = true;
          return;
        }
        this.exitPositionOrderForm.price = Number(this.exitPositionOrderForm.price);
        this.exitPositionOrderForm.size = this.showExitPositionOrder.size;
        this.exitPositionOrderForm.symbol = this.showExitPositionOrder.symbol;
        this.exitPositionOrderForm.exchangeId = this.showExitPositionOrder.exchangeId;
        // 退出哪单就将哪一单赋值给自己的父级订单主键
        this.exitPositionOrderForm.spotOrderId = this.showExitPositionOrder.spotOrderId;
        this.exitPositionOrderForm.id = this.showExitPositionOrder.id;
        if ('open_long' == this.showExitPositionOrder.side) {
          this.exitPositionOrderForm.side = 'close_sell';
        } else if ('open_short' == this.showExitPositionOrder.side) {
          this.exitPositionOrderForm.side = 'close_buy';
        } else {
          this.$store.dispatch('snackbarMessageHandler', 'Invalid Side');
          this.isEditingExitPositionOrder = true;
          return;
        }
        this.exitPositionOrderLoading = true;
        let api = config.baseUrl + '/order/spot/private/addExitOrder';
        this.$http.post(api, this.exitPositionOrderForm).then(response => {
          let data = response.data;
          this.exitPositionOrderLoading = false;
          this.isEditingExitPositionOrder = true;
          if (data.code == 200) {
            this.exitPositionOrderDialog = false;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.exitPositionOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 将驼峰格式化为单词组
      formatStr(str) {
        str = str.replace(/([A-Z])/g, "-$1").toLowerCase();
        var a = str.split("-");
        var re = "";
        for (var i = 0; i < a.length; i++) {
          if (i == 0) {
            re = a[i].slice(0, 1).toUpperCase() + a[i].slice(1);
          } else {
            re = re + " " + a[i].slice(0, 1).toUpperCase() + a[i].slice(1);
          }
        }
        return re;
      },
      // 止损价百分比计划设置
      plan(plan) {
        switch (plan) {
          case "0":
            this.trailingStopLevel1 = 0;
            this.priceLevel2 = 0;
            this.trailingStopLevel2 = 0;
            this.priceLevel3 = 0;
            this.trailingStopLevel3 = 0;
            break;
          case "1":
            this.trailingStopLevel1 = 15;
            this.priceLevel2 = 8;
            this.trailingStopLevel2 = 10;
            this.priceLevel3 = 10;
            this.trailingStopLevel3 = 8;
            break;
          case "2":
            this.trailingStopLevel1 = 10;
            this.priceLevel2 = 5;
            this.trailingStopLevel2 = 8;
            this.priceLevel3 = 10;
            this.trailingStopLevel3 = 5;
            break;
          case "3":
            this.trailingStopLevel1 = 15;
            this.priceLevel2 = 8;
            this.trailingStopLevel2 = 10;
            this.priceLevel3 = 15;
            this.trailingStopLevel3 = 8;
            break;
          case "4":
            this.trailingStopLevel1 = 20;
            this.priceLevel2 = 10;
            this.trailingStopLevel2 = 15;
            this.priceLevel3 = 20;
            this.trailingStopLevel3 = 10;
            break;
          case "5":
            this.trailingStopLevel1 = 0;
            this.priceLevel2 = 100;
            this.trailingStopLevel2 = 100;
            this.priceLevel3 = 100;
            this.trailingStopLevel3 = 100;
            break;
          case "MR":
            this.priceLevel2 = 100;
            this.trailingStopLevel2 = this.trailingStopLevel1;
            this.priceLevel3 = 100;
            this.trailingStopLevel3 = this.trailingStopLevel1;
            break;
          default:
            break;
        }
      },
      // 显示切割订单的弹出框
      showSplitOrderDialog(spotOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showSplitSpotOrder = JSON.parse(JSON.stringify(spotOrder));
        this.splitSpotOrderForm = JSON.parse(JSON.stringify(spotOrder));
        this.splitSpotOrderTrailingStopForm.trailingStopLevel1 = Number(Number(this.splitSpotOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.splitSpotOrderTrailingStopForm.trailingStopLevel2 = Number(Number(this.splitSpotOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.splitSpotOrderTrailingStopForm.trailingStopLevel3 = Number(Number(this.splitSpotOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.splitSpotOrderTrailingStopForm.priceLevel2 = Number(Number(this.splitSpotOrderForm.priceLevel2) *
          100).toFixed(2);
        this.splitSpotOrderTrailingStopForm.priceLevel3 = Number(Number(this.splitSpotOrderForm.priceLevel3) *
          100).toFixed(2);
        this.splitOrderDialog = true;
        this.splitSpotOrderForm.exchange = spotOrder.exchange;
      },
      // 关闭分割订单的弹出框
      closeSplitOrderDialog() {
        this.splitOrderDialog = false;
        this.splitOrderLoading = false;
        this.splitSpotOrderTrailingStopForm.trailingStopLevel1 = 0;
        this.splitSpotOrderTrailingStopForm.trailingStopLevel2 = 0;
        this.splitSpotOrderTrailingStopForm.trailingStopLevel3 = 0;
        this.splitSpotOrderTrailingStopForm.priceLevel2 = 0;
        this.splitSpotOrderTrailingStopForm.priceLevel3 = 0;
        this.showSplitSpotOrder = {};
        this.splitSpotOrderForm = {};
      },
      // 切割已全部成交的持仓订单
      splitSpotOrder() {
        this.splitOrderLoading = true;
        this.splitSpotOrderForm.trailingStopLevel1 = Number(Number(Number(this.splitSpotOrderTrailingStopForm.trailingStopLevel1)
          .toFixed(4)) / 100).toFixed(4);
        this.splitSpotOrderForm.trailingStopLevel2 = Number(Number(Number(this.splitSpotOrderTrailingStopForm.trailingStopLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.splitSpotOrderForm.trailingStopLevel3 = Number(Number(Number(this.splitSpotOrderTrailingStopForm.trailingStopLevel3)
          .toFixed(4)) / 100).toFixed(4);
        this.splitSpotOrderForm.priceLevel2 = Number(Number(Number(this.splitSpotOrderTrailingStopForm.priceLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.splitSpotOrderForm.priceLevel3 = Number(Number(Number(this.splitSpotOrderTrailingStopForm.priceLevel3)
          .toFixed(4)) / 100).toFixed(4);
        let spotOrderParam = {
          id: this.splitSpotOrderForm.id,
          size: Number(this.splitSpotOrderForm.size),
          takeProfitPrice: Number(this.splitSpotOrderForm.takeProfitPrice),
          trailingStopLevel1: Number(this.splitSpotOrderForm.trailingStopLevel1),
          trailingStopLevel2: Number(this.splitSpotOrderForm.trailingStopLevel2),
          trailingStopLevel3: Number(this.splitSpotOrderForm.trailingStopLevel3),
          priceLevel2: Number(this.splitSpotOrderForm.priceLevel2),
          priceLevel3: Number(this.splitSpotOrderForm.priceLevel3)
        };
        let api = config.baseUrl + '/order/spot/private/split';
        this.$http.post(api, spotOrderParam).then(response => {
          let data = response.data;
          this.splitOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          if (data.code == 200) {
            this.closeSplitOrderDialog();
          }
        }, error => {
          this.splitOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 显示修改备注的弹框
      showChangeNoteDialog(orderStatus, order) {
        if (order) {
          if (order.strategy) {
            this.changeNoteForm.strategy = order.strategy;
          } else {
            this.changeNoteForm.strategy = this.strategies[0];
          }
          if (order.category) {
            this.changeNoteForm.category = order.category;
          } else {
            this.changeNoteForm.category = this.orderCategories[0];
          }
          this.changeNoteForm.id = order.id;
          this.changeNoteForm.orderStatus = orderStatus;
          this.changeNoteForm.order = order;
          this.changeNoteDialog = true;
        }
      },
      // 跳转到批量编辑订单页面
      gotoBatchOrder() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "batchOrder"
            });
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 复制到剪切板
      copyToClipboard(message) {
        this.$copyText(message).then(res => {
          // 复制成功
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        }).catch(err => {
          // 复制失败
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        })
      },
      // 计算止盈预估收益/损失
      calcTakeProfitEstimatedPL(spotTrading) {
        if (spotTrading != null) {
          if (spotTrading.takeProfitPrice == 0) {
            return '--';
          }
          let pl = 0;
          if (spotTrading.side == 'open_long') {
            pl = (spotTrading.takeProfitPrice - spotTrading.price) * spotTrading.size;
          } else if (spotTrading.side == 'open_short') {
            pl = (spotTrading.price - spotTrading.takeProfitPrice) * spotTrading.size;
          } else {
            return '--'
          }
          pl = Number(pl.toFixed(2));
          if (pl < 0) {
            pl = -pl;
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '($' + pl + ')';
          } else {
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '$' + pl;
          }
        } else {
          return '--'
        }
      },
      // 计算安全网预估收益/损失
      calcSafetyNetEstimatedPL(spotTrading) {
        if (spotTrading != null) {
          if (spotTrading.stopLossPrice1 == 0) {
            return '--';
          }
          let pl = 0;
          if (spotTrading.side == 'open_long') {
            pl = (spotTrading.stopLossPrice1 - spotTrading.price) * spotTrading.size;
          } else if (spotTrading.side == 'open_short') {
            pl = (spotTrading.price - spotTrading.stopLossPrice1) * spotTrading.size;
          } else {
            return '--'
          }
          pl = Number(pl.toFixed(2));
          if (pl < 0) {
            pl = -pl;
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '($' + pl + ')';
          } else {
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '$' + pl;
          }
        } else {
          return '--'
        }
      },
      // 计算预估收益/损失
      calcEstimatedPL(spotTrading) {
        if (spotTrading != null) {
          if (spotTrading.beFixedStopLossPrice) {
            let pl = 0;
            // 是固定止损
            if (spotTrading.fixedStopLossPrice == 0) {
              return '--';
            }
            if (spotTrading.side == 'open_long') {
              pl = (spotTrading.fixedStopLossPrice - spotTrading.price) * spotTrading.size;
            } else if (spotTrading.side == 'open_short') {
              pl = (spotTrading.price - spotTrading.fixedStopLossPrice) * spotTrading.size;
            } else {
              return '--'
            }
            pl = Number(pl.toFixed(2));
            if (pl < 0) {
              pl = -pl;
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '($' + pl + ')';
            } else {
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '$' + pl;
            }
          } else {
            // 是追踪止损
            if (spotTrading.stopLossPrice == 0) {
              return '--';
            }
            let pl = 0;
            if (spotTrading.side == 'open_long') {
              pl = (spotTrading.stopLossPrice - spotTrading.price) * spotTrading.size;
            } else if (spotTrading.side == 'open_short') {
              pl = (spotTrading.price - spotTrading.stopLossPrice) * spotTrading.size;
            } else {
              return '--'
            }
            pl = Number(pl.toFixed(2));
            if (pl < 0) {
              pl = -pl;
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '($' + pl + ')';
            } else {
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '$' + pl;
            }
          }
        } else {
          return '--'
        }
      },
      // 打开交易所页面
      openExchangePage() {
        let url = '';
        switch (this.exchangeCode) {
          case 'kraken': url = 'https://trade.kraken.com/zh-cn/markets/kraken/btc/usd'; break;
          case 'okex': url = 'https://www.okex.me/spot/trade'; break;
          case 'binance': url = 'https://www.binance.com/en/trade/BTC_USDT'; break;
          case 'ftx': url = 'https://ftx.com/markets/spot'; break;
          default: break;
        }
        window.open(url, "_blank");
      },
      // 获取基础货币
      getBaseCurrency(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return '';
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            return currentExchangeSpotSymbolInfos[i].baseCurrency;
          }
        }
        return '';
      },
      // 获取目标货币
      getQuoteCurrency(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return '';
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            return currentExchangeSpotSymbolInfos[i].quoteCurrency;
          }
        }
        return '';
      },
      // 获取最小交易量
      getMinSize(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return 0;
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            return currentExchangeSpotSymbolInfos[i].minSize;
          }
        }
        return 0;
      },
      // 获取数量增量
      getSizeIncrement(exchange, symbol) {
        if (exchange == null || symbol == null) {
          return '--';
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange];
        for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            // 20220726：先根据sizeIncrement判断，若为0，则使用sizeDecimalPlace
            let sizeIncrement = currentExchangeSpotSymbolInfos[i].sizeIncrement;
            if(sizeIncrement > 0) {
              return sizeIncrement;
            } else {
              let sizeDecimalPlace = currentExchangeSpotSymbolInfos[i].sizeDecimalPlace;
              return (1 / Math.pow(10, sizeDecimalPlace)).toFixed(sizeDecimalPlace);
            }
          }
        }
        return '--';
      },
      // 根据策略获得类别
      getCategoriesByStrategy(strategy) {
        switch (strategy) {
          case 'PP':
          case 'CPP':
            return this.orderCategories;
          default: 
            let categories = ['TP'];
            return categories;
        }
      },
      // 更新类别
      updateStrategyAndCategory() {
        this.changeNoteLoading = true;
        let api = config.baseUrl + '/order/spot/private/edit/strategyAndCategory';
        let param = {
          id: this.changeNoteForm.id,
          strategy: this.changeNoteForm.strategy,
          category: this.changeNoteForm.category
        };
        this.$http.put(api, param).then(response => {
          let data = response.data;
          this.changeNoteLoading = false;
          this.changeNoteDialog = false;
          if (data.code == 200) {
            let orderList = null;
            switch (this.changeNoteForm.orderStatus) {
              case 'pendingEntry':
                orderList = this.pendingEntryOrders;
                break;
              case 'pendingExit':
                orderList = this.pendingExitOrders;
                break;
              case 'submitted':
                orderList = this.submittedOrders;
                break;
              case 'unfilled':
                orderList = this.unfilledExchangeOrders;
                break;
              case 'filled':
                orderList = this.filledExchangeOrders;
                break;
              case 'openPositions':
                orderList = this.openPositionsOrders;
                break;
              default:
                break;
            }
            if (orderList) {
              for (let i = 0, len = orderList.length; i < len; i++) {
                if (orderList[i].id == this.changeNoteForm.id) {
                  orderList[i].strategy = this.changeNoteForm.strategy;
                  orderList[i].category = this.changeNoteForm.category;
                  switch (this.changeNoteForm.category) {
                    case 'IOB':
                      orderList[i].bgColor = 'palered--text';
                      break;
                    case 'TA':
                      orderList[i].bgColor = 'palegreen--text';
                      break;
                    case 'MR':
                      orderList[i].bgColor = 'paleyellow--text';
                      break;
                    case 'TP':
                      orderList[i].bgColor = 'red--text';
                      break;
                    default:
                      orderList[i].bgColor = '';
                      break;
                  }
                  break;
                }
              }
            }
            this.changeNoteForm.id = null;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.changeNoteLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 查询显示下单按钮
      getOrderButtonSide() {
        if (this.buttonSide == 'long') {
          return this.$t('common.long');
        } else if (this.buttonSide == 'short') {
          return this.$t('common.short');
        } else if (this.buttonSide == 'buy') {
          return this.$t('common.buy');
        } else if (this.buttonSide == 'sell') {
          return this.$t('common.sell');
        }
      },
      // 获取价格跳点
      getTickSize(exchange, symbol){
        if (exchange == null || symbol == null) {
          return 1;
        }
        let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange.toLowerCase()];
        for(let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++){
          if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
            return Number(Math.pow(10, -currentExchangeSpotSymbolInfos[i].priceDecimalPlace).toFixed(currentExchangeSpotSymbolInfos[i].priceDecimalPlace));
          }
        }
        return 1;
      },
      // 接收改变的renko策略
      receiveChangeRenkoStrategy(strategy){
        if (strategy) {
          this.orderForm.strategy = strategy;
        }
      },
      // 接收的renko价格
      receiveRenkoPrice(price){
        if (price >= 0) {
          this.orderForm.price = price;
        }
      },
      // 刷新止盈和止损价
      receiveResetTakeAndStopPrice(){
        this.renkoChartTakeProfitPrice = 0;
        this.renkoChartStopLossPrice = 0;
      },
      // 输入订单价格
      enterOrderPrice(price){
        if (price >= 0) {
          this.orderForm.price = price;
        }
      },
      queryPendingEntryOrdersByPages(pageNo){
        this.getPendingEntryOrdersByPages(pageNo, true);
      },
      queryPendingExitOrdersByPages(pageNo){
        this.getPendingExitOrdersByPages(pageNo, true);
      },
      querySubmittedOrdersByPages(pageNo){
        this.getSubmittedOrdersByPages(pageNo, true);
      },
      queryUnfilledExchangeOrdersByPages(pageNo){
        this.getUnfilledExchangeOrdersByPages(pageNo, true);
      },
      queryFilledExchangeOrdersByPages(pageNo){
        this.getFilledExchangeOrdersByPages(pageNo, true);
      },
      queryOpenPositionsOrdersByPages(pageNo){
        this.getOpenPositionsOrdersByPages(pageNo, true);
      },
      // 获取WebSocket数据
      getWebSocketData(){
        if (this.webSocketData == null) {
          return;
        }
        // 是否为订单数据
        let isOrderData = false;
        if (this.webSocketData.PENDING_ENTRY_SPOT_ORDER) {
          this.getPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.PENDING_EXIT_SPOT_ORDER){
          this.getPendingExitOrdersByPages(this.pendingExitOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.SUBMITTED_SPOT_ORDER){
          this.getSubmittedOrdersByPages(this.submittedOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.UNFILLED_SPOT_ORDER){
          this.getUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.FILLED_SPOT_ORDER){
          this.getFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.OPEN_POSITIONS_SPOT_ORDER){
          this.getOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo, false);
          isOrderData = true;
        }
        if (this.webSocketData.OPEN_POSITIONS_AT_EXCHANGES_SPOT_ORDER) {
          this.getOpenPositionsAtExchanges();
          isOrderData = true;
        }
        if (isOrderData) {
          // 是订单数据，才清除webSocketData，否则可能是其他数据
          this.$store.dispatch('webSocketDataHandler', null);
        }
      },
      // 加载页面缓存
      getLocalStorage(){
        let hideNonePositions = localStorage.getItem('iobots-hideSpotNonePositions');
        if (hideNonePositions) {
          this.hideNonePositions = hideNonePositions == 1;
        } else {
          localStorage.setItem('iobots-hideSpotNonePositions', 0);
        }
        let childComponent = localStorage.getItem('iobots-spotChildComponent');
        if (childComponent) {
          this.childComponent = childComponent;
        } else {
          localStorage.setItem('iobots-spotChildComponent', 'candle');
        }
      },
      // 查询放置的进退入订单的价格
      getOpenPrices(){
        let openLongPrices = [];
        let openShortPrices = [];
        // 放置的进入订单
        for(let i = 0, len = this.pendingEntryOrders.length; i < len; i++){
          let order = this.pendingEntryOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              openLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              openShortPrices.push(order.price);
            }
          }
        }
        // 未成交的订单
        for(let i = 0, len = this.unfilledExchangeOrders.length; i < len; i++){
          let order = this.unfilledExchangeOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              openLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              openShortPrices.push(order.price);
            }
          }
        }
        // 结果对象
        let result = {
          openLongPrices: openLongPrices,
          openShortPrices: openShortPrices
        };
        return result;
      },
      // 查询持仓的止盈和止损的价格
      getOpenPositionsPrices(){
        let entryLongPrices = [];
        let entryShortPrices = [];
        let takeProfitPrices = [];
        let stopLossPrices = [];
        for(let i = 0, len = this.openPositionsOrders.length; i < len; i++){
          let order = this.openPositionsOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              entryLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              entryShortPrices.push(order.price);
            }
            if (order.takeProfitPrice > 0) {
              takeProfitPrices.push(order.takeProfitPrice);
            }
            if (order.beFixedStopLossPrice) {
              // 固定止损
              stopLossPrices.push(order.fixedStopLossPrice);
            } else {
              // 移动止损
              stopLossPrices.push(order.stopLossPrice);
            }
          }
        }
        // 结果对象
        let result = {
          entryLongPrices: entryLongPrices,
          entryShortPrices: entryShortPrices,
          takeProfitPrices: takeProfitPrices,
          stopLossPrices: stopLossPrices
        }
        return result;
      },
      // 根据策略查询颜色
      getColorByStrategy(strategy){
        return this.strategyColors[strategy];
      },
    },
    beforeDestroy() {
      this.$store.dispatch('documentTitleHandler', this.$t("common.documentTitle"));
    }
  }
</script>
<style scoped>
  ::-webkit-scrollbar {
    width: 2px !important;
    background-color: #fff;
  }
  /*定义滚动条轨道 内阴影+圆角*/  
  ::-webkit-scrollbar-track  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 0px;
      background-color: #9e9e9e;  
  }
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }
  /*火狐浏览器的滚动条*/
  .firefox-scroll {
    scrollbar-color: #9e9e9e;
    scrollbar-width: thin;
  }
</style>