<template>
  <div>
    <v-progress-linear indeterminate color="grey" height="2" v-if="loading" class="mt-2" ></v-progress-linear>
    <div v-if="responsive == 'PC'" class="my-0 py-0 px-0">
      <v-divider class="my-0" v-if="!loading"></v-divider>
        <v-row no-gutters dense>
          <!-- Symbol -->
          <v-col cols="1" class="mx-0 px-0">
            <v-data-table dense :headers="headerSymbol" :items="summaries" hide-default-footer :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" disable-pagination>
              <template v-slot:header>
                <thead>
                    <tr>
                      <th></th>
                    </tr>
                </thead>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i" v-show="hideNonePositions == false ? true : (item.longQuantity != 0 || item.shortQuantity !=0)">
                    <td class="fs-12">{{ item.currency}}</td>
                  </tr>
                  <tr>
                    <td>{{$t("assetSummary.subTotal")}}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
          <!-- Long -->
          <v-col cols="5" class="mx-0 px-0">
            <v-data-table dense :headers="headerLong" :items="summaries" hide-default-footer :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" disable-pagination>
              <template v-slot:header>
                <thead>
                    <tr>
                      <th colspan="7" class="text-center">{{$t("assetSummary.long")}}</th>
                    </tr>
                </thead>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i" v-show="hideNonePositions == false ? true : (item.longQuantity != 0 || item.shortQuantity !=0)">
                      <td class="fs-12 text-right">{{ item.longQuantity == 0 ? '' : formatNum(item.longQuantity) }}</td>
                      <td class="fs-12 text-right">{{ item.longAvgPrice == 0 ? '' : formatNum(item.longAvgPrice.toFixed(getDecimalPlace(item.currency))) }}</td>
                      <td class="fs-12 text-right">{{ item.longInitValue == 0 ? '' : formatNum(item.longInitValue.toFixed(2))}}</td>
                      <td class="fs-12 text-right">{{ item.longQuantity != 0 ? (item.longCurrentValue == 0 ? '0.00' : formatNum(item.longCurrentValue.toFixed(2))) :''}}</td>
                      <td class="fs-12 text-right" :class=" item.longPl < 0 ? 'red--text' : ( item.longPl == 0 ? '' : 'green--text' )">{{ item.longPl < 0 ? '(' : '' }}{{ item.longQuantity != 0 ? (item.longPl == 0 ? "0.00" : formatNum(Math.abs(item.longPl).toFixed(2))) : "" }}{{ item.longPl < 0 ? ')' : '' }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="fs-12 text-right">{{ formatNum(subLongIniValue.toFixed(2)) }}</td>
                    <td class="fs-12 text-right">{{ formatNum(subLongCurrentValue.toFixed(2)) }}</td>
                    <td class="fs-12 text-right" :class="subLongPL < 0 ? 'red--text' : (subLongPL > 0 ? 'green--text' : '')">{{ subLongPL < 0 ? '(' : '' }}{{ formatNum(Math.abs(subLongPL).toFixed(2)) }}{{ subLongPL < 0 ? ')' : '' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
          <!-- Short -->
          <v-col cols="5" class="mx-0 px-0">
            <v-data-table :headers="headerShort" :items="summaries" dense hide-default-footer :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" disable-pagination>
              <template v-slot:header>
                <thead>
                  <tr>
                    <th colspan="7" class="text-center">{{$t("assetSummary.short")}}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i" v-show="hideNonePositions == false ? true : (item.longQuantity != 0 || item.shortQuantity !=0)">
                      <td class="fs-12 text-right">{{ item.shortQuantity > 0 ? '-':''}}{{ item.shortQuantity == 0 ? '' : formatNum(item.shortQuantity) }}</td>
                      <td class="fs-12 text-right">{{ item.shortAvgPrice == 0 ? '' : formatNum(item.shortAvgPrice.toFixed(getDecimalPlace(item.currency))) }}</td>
                      <td class="fs-12 text-right">{{ item.shortInitValue > 0 ? '-':''}}{{ item.shortInitValue == 0 ? '' : formatNum(item.shortInitValue.toFixed(2))}}</td>
                      <td class="fs-12 text-right">{{ item.shortCurrentValue > 0 ? '-':''}}{{ item.shortQuantity !=0 ? (item.shortCurrentValue == 0 ? '0.00' : formatNum(item.shortCurrentValue.toFixed(2))) :''  }}</td>
                      <td class="fs-12 text-right" :class=" item.shortPl < 0 ? 'red--text' : ( item.shortPl == 0 ? '' : 'green--text' )">{{ item.shortPl < 0 ? '(' : '' }}{{ item.shortQuantity !=0 ? (item.shortPl == 0 ? "0.00" : formatNum(Math.abs(item.shortPl).toFixed(2))) : "" }}{{ item.shortPl < 0 ? ')' : '' }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="fs-12 text-right">{{ formatNum(subShortIniValue.toFixed(2)) }}</td>
                    <td class="fs-12 text-right">{{ formatNum(subShortCurrentValue.toFixed(2)) }}</td>
                    <td class="fs-12 text-right" :class="subShortPL < 0 ? 'red--text' : (subShortPL > 0 ? 'green--text' : '')">{{ subShortPL < 0 ? '(' : '' }}{{ formatNum(Math.abs(subShortPL).toFixed(2)) }}{{ subShortPL < 0 ? ')' : '' }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
          <!-- Asset Net -->
          <v-col cols="1" class="mx-0 px-0">
            <v-data-table :headers="headerAssetNet" :items="summaries" dense hide-default-footer :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" disable-pagination>
              <template v-slot:header>
                <thead>
                  <tr>
                    <th colspan="2" class="text-center">{{$t("assetSummary.net")}}</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i" v-show="hideNonePositions == false ? true : (item.longQuantity != 0 || item.shortQuantity !=0)">
                    <td class="fs-12 text-right" :class=" item.assetNet < 0 ? 'red--text' : ''">{{ item.assetNet < 0 ? '-' : '' }}{{ item.longQuantity != 0 || item.shortQuantity !=0? (item.assetNet == 0 ? "0.00" : formatNum(Math.abs(item.assetNet).toFixed(2))) : "" }}</td>
                    <td class="fs-12 text-right" :class=" item.plNet < 0 ? 'red--text' : (item.plNet > 0 ? 'green--text' : '')">{{ item.plNet < 0 ? '(' : '' }}{{ item.longQuantity != 0 || item.shortQuantity !=0 ? (item.plNet == 0 ? "0.00" : formatNum(Math.abs(item.plNet).toFixed(2))) : "" }}{{ item.plNet < 0 ? ')' : '' }}</td>
                  </tr>
                   <tr>
                      <td class="fs-12 text-right" :class="subAssetNet < 0 ? 'red--text' : ''">{{ subAssetNet < 0 ? '-' : '' }}{{ formatNum(Math.abs(subAssetNet).toFixed(2)) }}</td>
                      <!-- <td class="fs-12 text-right" :class="subNetPl < 0 ? 'red--text' : (subNetPl > 0 ? 'green--text' : '')">{{ subNetPl < 0 ? '(' : '' }}{{ formatNum(Math.abs(subNetPl).toFixed(4)) }}{{ subNetPl < 0 ? ')' : '' }}</td> -->
                      <td class="fs-12 text-right" :class="subNetPl < 0 ? 'red--text' : (subNetPl > 0 ? 'green--text' : '')">{{ subNetPl < 0 ? '(' : '' }}{{ formatNum(Math.abs(subNetPl).toFixed(2)) }}{{ subNetPl < 0 ? ')' : '' }}</td>
                    </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
    </div>
    <!-- 手机版 -->
    <v-row v-if="responsive == 'mobile'">
      <!-- <v-col cols="12" no-gutters v-show="allOpenPositionsQuantity > 0"> -->
      <v-col cols="12" no-gutters>
        <!-- 小计 -->
        <v-row no-gutters justify="center" align="center">
          <v-card flat class="mt-2 my-3 mx-3">
            <v-row class="ma-3" no-gutters>
              <v-col cols="12" no-gutters>
                <span>{{ $t('assetSummary.subTotal') }}</span>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <span class="grey--text text--lighten-1">{{$t("assetSummary.assetNetMobile")}}</span>
                <span class="float-right" :class="subAssetNet < 0 ? 'red--text' : (subAssetNet > 0 ? 'green--text' : '')">{{ subAssetNet < 0 ? '(' : '' }}{{ formatNum(Math.abs(subAssetNet).toFixed(2)) }}{{ subAssetNet < 0 ? ')' : '' }}</span>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <span class="grey--text text--lighten-1">{{$t("assetSummary.plNetMobile")}}</span>
                <span class="float-right" :class="subNetPl < 0 ? 'red--text' : (subNetPl > 0 ? 'green--text' : '')">{{ subNetPl < 0 ? '(' : '' }}{{ formatNum(Math.abs(subNetPl).toFixed(2)) }}{{ subNetPl < 0 ? ')' : '' }}</span>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="4"></v-col>
                  <v-col cols="4"><span class="text--lighten-1 float-right">{{$t("assetSummary.long")}}</span></v-col>
                  <v-col cols="4"><span class="text--lighten-1 float-right">{{$t("assetSummary.short")}}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.iniValue")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(subLongIniValue.toFixed(2)) }}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ formatNum(subShortIniValue.toFixed(2)) }}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.currentValue")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(subLongCurrentValue.toFixed(2)) }}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ formatNum(subShortCurrentValue.toFixed(2)) }}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.pl")}}</v-col>
                  <v-col cols="3"><span class="float-right" :class="subLongPL < 0 ? 'red--text' : (subLongPL > 0 ? 'green--text' : '')">{{ subLongPL < 0 ? '(' : '' }}{{ formatNum(Math.abs(subLongPL).toFixed(2)) }}{{ subLongPL < 0 ? ')' : '' }}</span></v-col>
                  <v-col cols="4"><span class="float-right" :class="subShortPL < 0 ? 'red--text' : (subShortPL > 0 ? 'green--text' : '')">{{ subShortPL < 0 ? '(' : '' }}{{ formatNum(Math.abs(subShortPL).toFixed(2)) }}{{ subShortPL < 0 ? ')' : '' }}</span></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <!-- 小计结束 -->
        <!-- 遍历数据 -->
        <v-row no-gutters justify="center" align="center">
          <v-card flat v-for="(item, i) in summaries" :key="i" class="mt-2 my-3 mx-3" v-show="item.longQuantity != 0 || item.shortQuantity !=0 ">
            <v-row class="ma-3" no-gutters>
              <v-col cols="12" no-gutters>
                <span class="grey--text text--lighten-1">{{$t("assets.currency")}}</span>
                <span class="float-right">{{ item.currency + '-USDT' }}</span>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <span class="grey--text text--lighten-1">{{$t("assetSummary.assetNet")}}</span>
                <span class="float-right" :class=" item.assetNet < 0 ? 'red--text' : ''">{{ item.assetNet < 0 ? '-' : '' }}{{ formatNum(Math.abs(item.assetNet).toFixed(2)) }}</span>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="4"></v-col>
                  <v-col cols="4"><span class="text--lighten-1 float-right">{{$t("assetSummary.long")}}</span></v-col>
                  <v-col cols="4"><span class="text--lighten-1 float-right">{{$t("assetSummary.short")}}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.contractQty")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(item.longQuantity) }}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ item.shortQuantity > 0 ? '-' :''}}{{ formatNum(item.shortQuantity) }}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.avgPrice")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(item.longAvgPrice.toFixed(getDecimalPlace(item.currency))) }}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ formatNum(item.shortAvgPrice.toFixed(getDecimalPlace(item.currency)))}}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.iniValue")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(item.longInitValue.toFixed(2))}}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ item.shortInitValue > 0 ? '-' : ''}}{{ formatNum(item.shortInitValue.toFixed(2))}}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.currentValue")}}</v-col>
                  <v-col cols="3"><span class="float-right">{{ formatNum(item.longCurrentValue.toFixed(2))}}</span></v-col>
                  <v-col cols="4"><span class="float-right">{{ item.shortCurrentValue > 0 ? '-' : ''}}{{ formatNum(item.shortCurrentValue.toFixed(2))}}</span></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" py-0 px-0 mx-3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" no-gutters>
                <v-row no-gutters>
                  <v-col cols="5" class="grey--text text--lighten-1">{{$t("assetSummary.pl")}}</v-col>
                  <v-col cols="3"><span class="float-right" :class=" item.longPl < 0 ? 'red--text' : ( item.longPl == 0 ? '' : 'green--text' )">{{ item.longPl < 0 ? '(' : '' }}{{ formatNum(Math.abs(item.longPl).toFixed(2)) }}{{ item.longPl < 0 ? ')' : '' }}</span></v-col>
                  <v-col cols="4"><span class="float-right" :class=" item.shortPl < 0 ? 'red--text' : ( item.shortPl == 0 ? '' : 'green--text' )">{{ item.shortPl < 0 ? '(' : '' }}{{ formatNum(Math.abs(item.shortPl).toFixed(2)) }}{{ item.shortPl < 0 ? ')' : '' }}</span></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style> 
</style>
<script>
  import config from '@/config.js';
  import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              headerSymbol:[
                { text: this.$t('assetSummary.inst'), value:"inst", sortable: false, class:'fs-12'},
              ],
              headerLong:[
                { text: this.$t('assetSummary.contractQty'), value:"ContractQty", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.avgPrice'), value:"avgPrice", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.iniValue'), value:"iniValue", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.currentValue'), value:"currentValue", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.pl'), value:"pl", sortable: false, align: 'right',class:'fs-12'},
              ],
              headerShort:[ 
                { text: this.$t('assetSummary.contractQty'), value:"contractQty", sortable: false,align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.avgPrice'), value:"avgPrice", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.iniValue'), value:"iniValue", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.currentValue'), value:"currentValue", sortable: false, align: 'right',class:'fs-12'},
                { text: this.$t('assetSummary.pl'), value:"pl", sortable: false, align: 'right',class:'fs-12'},
              ],
              headerAssetNet:[
                { text: this.$t('assetSummary.assetNet'), value:"assetNet", sortable: false, align: 'right',width:'20%'},
                { text: this.$t('assetSummary.plNet'), value:"plNet", sortable:false, align:'right', class:'fs-12'}
              ],
              summaries: [],
              loading: true,
              // 所有持仓
              allOpenPositionsQuantity: 0
            }
        },
        props:['hideNonePositions','mini'],
        created(){
          this.getAssetSummary();
        },
        mounted(){

        },
        computed: {
          ...mapGetters(['spotSummary','responsive','darkMode', 'spotSymbolInfos']),
          subLongIniValue(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].longInitValue;
              }
            }
            return total;
          },
          subLongCurrentValue(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].longCurrentValue;
              }
            }
            return total;
          },
          subLongPL(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].longPl;
              }
            }
            return total;
          },
          subShortIniValue(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].shortInitValue;
              }
            }
            return total == 0 ? total : -total;
          },
          subShortCurrentValue(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].shortCurrentValue;
              }
            }
            return total == 0 ? total : -total;
          },
          subShortPL(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].shortPl;
              }
            }
            return total;
          },
          subAssetNet(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].assetNet;
                this.allOpenPositionsQuantity += this.summaries[i].longQuantity;
                this.allOpenPositionsQuantity += this.summaries[i].shortQuantity;
              }
            }
            return total;
          },
          subNetPl(){
            var total = 0;
            if (this.summaries.length > 0){
              for (let i = 0; i < this.summaries.length; i++) {
                total += this.summaries[i].plNet;
              }
            }
            return total;
          },
        },
        watch:{

        },
        methods: {
          // 父级组价调用刷新方法
          refresh(){
            let api = config.baseUrl + "/asset/private/summary/delete?tradingCategory=spot";
            this.$http.delete(api).then(res => {
              let data = res.data;
              if (data.code == 200) {
                this.getAssetSummary();
              }
            }, error => {
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            })
          },
          getAssetSummary(){
            if (this.spotSummary) {
              this.summaries = this.spotSummary;
            }
            this.loading = true;
            let api = config.baseUrl + "/positions/summary/private/spot";
            this.$http.get(api).then(res=>{
              let data = res.data;
              if (data.code == 200){
                this.summaries = data.result;
                this.$store.dispatch('spotSummaryHandler', this.summaries);
                this.loading = false;
              }else {
                this.loading = false;
                this.$store.dispatch('snackbarMessageHandler', data.message);
              }
            }, error => {
              this.loading = false;
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            })
          },
          // 数字格式化
          formatNum(num) {
            return formatNumber(num);
          },
          // 获取小数位
          getDecimalPlace(currency){
            if (currency == null) {
              return 8;
            }
            let symbol1 = currency;
            let symbol2 = currency;
            // 不是BTC本位的就添加计价货币
            if(currency.indexOf('-BTC') == -1){
              symbol1 = currency + "-USDT";
              symbol2 = currency + "-USD";
            }
            let exchanges = Object.keys(this.spotSymbolInfos);
            for(let i = 0, len = exchanges.length; i < len; i++){
              let exchangeSymbols = this.spotSymbolInfos[exchanges[i]];
              for(let i = 0, len = exchangeSymbols.length; i < len; i++){
                if (exchangeSymbols[i].symbol == symbol1 || exchangeSymbols[i].symbol == symbol2) {
                  return exchangeSymbols[i].priceDecimalPlace;
                }
              }
            }

            return 8;
          },
        },
    }
</script>
